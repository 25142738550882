import {
  API_BASE_URL,
  DECISION_TYPES,
  DELIVERY_TYPES,
  INDIVIDUALIZED_TERMS_TYPES,
  ORDER_STATUSES,
  REGEX,
  ROUTES,
  SERVICE_TYPES,
  SIGNATURE_STATUSES,
  STEPS,
  STORE,
  LOCALES,
} from '@/constants';
import { parseServicePeriod, isMultipleYears } from '@/utils';

export const constants = {
  computed: {
    API_BASE_URL() {
      return API_BASE_URL;
    },
    ROUTES() {
      return ROUTES;
    },
    FEATURE_LOGIN() {
      return process.env.VUE_APP_FEATURE_LOGIN === '1';
    },
    SERVICE_TYPES() {
      return SERVICE_TYPES;
    },
    STEPS() {
      return STEPS;
    },
    REGEX() {
      return REGEX;
    },
    DECISION_TYPES() {
      return DECISION_TYPES;
    },
    INDIVIDUALIZED_TERMS_TYPES() {
      return INDIVIDUALIZED_TERMS_TYPES;
    },
    DELIVERY_TYPES() {
      return DELIVERY_TYPES;
    },
    SIGNATURE_STATUSES() {
      return SIGNATURE_STATUSES;
    },
    ORDER_STATUSES() {
      return ORDER_STATUSES;
    },
    STORE() {
      return STORE;
    },
    LOCALES() {
      return LOCALES;
    },
  },
};

export const getServicePeriod = {
  methods: {
    getServicePeriod(period) {
      const parsedPeriod = parseServicePeriod(period);

      if (!parsedPeriod) {
        return '';
      }

      return `${this.$tc(
        `${parsedPeriod.period.toUpperCase()}`,
        isMultipleYears(period)
      )} ${parsedPeriod.time}`;
    },
  },
};

export const createOptions = {
  methods: {
    createOptions(options = {}, labelPrefix) {
      return Object.keys(options).map((optionValue) => ({
        value: optionValue,
        label: labelPrefix
          ? `${this.$t(`${labelPrefix}.${options[optionValue].toUpperCase()}`)}`
          : this.$t(options[optionValue].toUpperCase()),
      }));
    },
  },
};
