import heartbeats from 'heartbeats';
import ApiService from '@/api';

const ALIVE_EVENT_NAME = 'alive';
const heart = heartbeats.createHeart(1000, 'heart');

function createEvent(beatInterval, options, callback) {
  heart.createEvent(beatInterval, options, callback);
}

function killEvent(name) {
  heart.killEvent(name);
}

function getEvent(name) {
  return heart.event(name);
}

async function createAliveHeartbeat() {
  const aliveEvent = getEvent(ALIVE_EVENT_NAME);
  if (!aliveEvent) {
    await ApiService.alive();
    createEvent(60, { name: ALIVE_EVENT_NAME }, ApiService.alive);
  }
}

function killAliveHeartbeat() {
  killEvent(ALIVE_EVENT_NAME);
}

function handleAliveHeartbeat(shouldBeAlive) {
  shouldBeAlive ? createAliveHeartbeat() : killAliveHeartbeat();
}

export default {
  handleAliveHeartbeat,
};
