export const API_BASE_URL = process.env.VUE_APP_API_URL;
export const SUPPORT_EMAIL = process.env.VUE_APP_SUPPORT_EMAIL;

export const ROUTES = {
  HOME: {
    path: '/',
    name: 'home',
  },
  SUBSCRIPTION: {
    path: '/subscription',
    name: 'subscription',
  },
  APPLY: {
    path: '/apply',
    name: 'apply',
  },
  SELECT_STORE: {
    path: '/select-store',
    name: 'select-store',
  },
  FAQ: {
    path: '/faq',
    name: 'faq',
  },
  RENEWAL: {
    path: '/apply/renewal/:reference',
    name: 'renewal',
  },
  LOAN_APPLICATION: {
    path: '/apply/loan-application',
    name: 'loan-application',
  },
  LOAN_APPLICATION_LOADING: {
    path: '/apply/loan-application/loading',
    name: 'loan-application-loading',
  },
  DECISION_MANUAL_INSPECTION: {
    path: '/decision/manual-inspection',
    name: 'decision-manual-inspection',
  },
  DECISION_DENIED: {
    path: '/decision/denied',
    name: 'decision-denied',
  },
  DECISION_SIGN: {
    path: '/decision/sign',
    name: 'decision-sign',
  },
  BANK_ID_SUCCESS: {
    path: '/bank-id/success',
    name: 'bank-id-success',
  },
  BANK_ID_FAIL: {
    path: '/bank-id/fail',
    name: 'bank-id-fail',
  },
  BACK_OFFICE: {
    path: '/back-office',
    name: 'back-office',
  },
  BACK_OFFICE_LOGIN: {
    path: '/login',
    name: 'back-office-login',
  },
  BACK_OFFICE_REGISTRATION: {
    path: '/registration/:invitationId',
    name: 'back-office-registration',
  },
  LOGIN_SUCCESS: {
    path: '/login-success',
    name: 'login-success',
  },
  LOGIN_FAIL: {
    path: '/login-fail',
    name: 'login-fail',
  },
  BACK_OFFICE_PASSWORD_RESET: {
    path: '/password-reset/:tokenId',
    name: 'back-office-password-reset',
  },
  BACK_OFFICE_PASSWORD_LOST: {
    path: '/password-lost',
    name: 'back-office-password-lost',
  },
};

export const SERVICE_TYPES = {
  SERVICE: 'SERVICE',
  GUARANTEE: 'GUARANTEE',
  DISCOUNT: 'DISCOUNT',
};

export const PERSISTED_STORE_KEY = 'fairown-persisted-store';

export const STEPS = {
  select: 0,
  apply: 1,
  decision: 2,
  sign: 3,
};

export const REGEX = {
  SE: {
    identificationNumber:
      '^(18\\d{2}|19\\d{2}|20\\d{2}|\\d{2})(0[1-9]|1[0-2])([0][1-9]|[1-2][0-9]|3[0-1])(\\-|\\+)?([\\d]{4})$',
    mobile:
      '^(0|\\+46|0046)[ |-]?(200|20|70|73|76|74|[1-9][0-9]{0,2})([ |-]?[0-9]){5,8}$',
    postalCode: '^\\d{3}\\s*\\d{2}$',
  },
};

export const DECISION_TYPES = {
  APPROVED: 'APPROVED',
  MANUAL_INSPECTION: 'MANUAL_INSPECTION',
  SUPPLEMENTING_REQUIRED: 'SUPPLEMENTING_REQUIRED',
  NONE: 'NONE',
  DENIED: 'DENIED',
};

export const INDIVIDUALIZED_TERMS_TYPES = ['COMMON', 'ISEKKI_NEW_ACCOUNT'];

export const DELIVERY_TYPES = {
  // HOME: 'HOME',
  STORE: 'STORE',
};

export const SIGNATURE_STATUSES = {
  CREATED: 'CREATED',
  NOTIFIED: 'NOTIFIED',
  STARTED: 'STARTED',
  COMPLETE: 'COMPLETE',
  CANCELLED: 'CANCELLED',
  UNKNOWN: 'UNKNOWN',
};

export const ORDER_STATUSES = {
  PENDING: 'PENDING',
  SUBMITTED: 'SUBMITTED',
  COMPLETED: 'COMPLETED',
  HANDED_OVER: 'HANDED_OVER',
  CANCELLED: 'CANCELLED',
  DENIED: 'DENIED',
  READY_TO_RENEW: 'READY_TO_RENEW',
  RENEWED: 'RENEWED',
  AWAITING_RENEWAL: 'AWAITING_RENEWAL',
};

export const LOCALES = {
  EN: 'en',
  SV: 'sv',
};

export const STORE = {
  name: 'Stjärnurmakarna',
  lang: 'se',
  availableLocales: [LOCALES.SV],
  mapConfig: {
    centerPosition: { lat: 61.7529118, lng: 15.1709969 },
    zoom: 6,
  },
};

export const USER_ROLE = {
  ADMIN: 'ADMIN',
  EMPLOYEE: 'EMPLOYEE',
};
