<template>
  <div class="marketing-hero">
    <div class="marketing-hero__overlay"></div>
    <Container class="marketing-hero__container text-center text-white">
      <img
        class="marketing-hero__logo"
        src="@/assets/images/klockabonnemang-logo.png"
        loading="lazy"
        alt="Stjärnurmakarna"
      />
      <Separator size="medium" />
      <div class="d-none d-md-block">
        <h1 class="marketing-hero__title text-uppercase">
          <span class="font-italic text-transform-none">{{
            $t('MARKETING.NEWS')
          }}</span>
          {{ $t('HEADER_MARKETING.TITLE') }}
        </h1>
      </div>
      <div class="d-md-none px-4 max-500">
        <p class="marketing-hero__title text-uppercase">
          {{ $t('HEADER_MARKETING.TITLE_MOBILE') }}
        </p>
      </div>
      <!-- <Separator size="medium" /> -->
      <p class="marketing-hero__text">
        {{ $t('HEADER_MARKETING.START_FROM') }}
        <span class="font-strong">149 {{ $t('HEADER_MARKETING.UNIT') }}</span
        >/{{ $t('HEADER_MARKETING.MONTH') }}
      </p>
      <Separator size="medium" />
      <Button class="mt-4" :to="{ name: ROUTES.SUBSCRIPTION.name }">
        {{ $t('HEADER_MARKETING.BUTTON') }}
      </Button>
    </Container>
  </div>
</template>

<script>
import { Container, Separator, Button } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'MarketingHero',
  mixins: [constants],
  components: {
    Container,
    Separator,
    Button,
  },
};
</script>

<style lang="scss">
.marketing-hero {
  position: relative;
  max-width: 100%;
  min-height: 90vh;
  padding-left: 0;
  padding-right: 0;
  background-image: url(../../assets/images/stjarnis_webb_16.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.marketing-hero__overlay {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.44);
}

.marketing-hero__container {
  position: relative;
  padding-bottom: 7rem;
}

.marketing-hero__logo {
  padding-top: 6.5rem;
  margin-left: auto;
  margin-right: auto;
}

.marketing-hero__title {
  position: relative;
  display: inline-block;
  font-size: 2.63rem;

  span {
    font-family: $font-family-accent;
    font-size: 2.44rem;
    font-weight: 400;
    position: absolute;
    transform: rotate(-15.87deg);

    left: -3rem;
    top: -2.5rem;
  }

  @include min-width(lg) {
    font-size: 5.5rem;

    span {
      font-size: 3.44rem;
      left: -4rem;
      top: -3rem;
    }
  }
}

.marketing-hero__text {
  font-size: 20px;

  span {
    font-family: $font-family-header;
    font-size: 28px;
  }

  @include min-width(lg) {
    font-size: 45px;

    span {
      font-size: 62px;
    }
  }
}

.max-500 {
  max-width: 500px;
  margin: auto;
}
</style>
