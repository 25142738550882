<template>
  <hr
    :class="[
      'separator',
      { 'separator--line': hasLine },
      { [`separator--${size}`]: !!size },
      { [`separator--line-thickness-${thickness}`]: !!thickness },
      { [`separator--line-color-${color}`]: !!color },
    ]"
  />
</template>

<script>
export default {
  name: 'Separator',
  props: {
    size: {
      validator: function (value) {
        return ['x-small', 'small', 'medium', 'large'].includes(value);
      },
    },
    thickness: {
      validator: function (value) {
        return ['medium'].includes(value);
      },
    },
    color: {
      validator: function (value) {
        return ['dark', 'light'].includes(value);
      },
    },
    hasLine: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.separator {
  border: none;
  border-top: rem(1px) solid transparent;
  margin: 0;
}

.separator--x-small {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.separator--small {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.separator--medium {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.separator--large {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.separator--line {
  border-color: $color-grey-border;
}

.separator--line-thickness-medium {
  border-top: rem(2px) solid transparent;
}

.separator--line-color-dark {
  border-color: $color-black-light;
}
</style>
