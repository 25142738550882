<template>
  <div class="back-office-bundle-orders">
    <Margins>
      <b-row
        align-h="start"
        class="d-flex align-items-center flex-wrap"
        no-gutters
      >
        <b-col cols="5">
          <b-row align-h="start" class="d-flex align-items-start" no-gutters>
            <b-col cols="12" md="6" class="px-2 py-2">
              <InputField
                v-model="filtersForm.referenceNumber"
                id="referenceNumberFilter"
                name="referenceNumberFilter"
                type="text"
                size="small"
                labelHidden
                :placeholder="$t('SEARCH_BY_REFERENCE_NUMBER')"
                :label="$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER')"
                v-validate="'required|min:3'"
                :data-vv-as="$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER')"
                data-vv-scope="formSearchReferenceNumber"
                data-vv-validate-on="querySearchReferenceNumber"
                :error="
                  errors.first(
                    'formSearchReferenceNumber.referenceNumberFilter'
                  )
                "
                @change="querySearchReferenceNumber"
              >
                <template slot="suffix">
                  <Button
                    variant="ghost"
                    size="small"
                    @click="clearFilter('referenceNumberFilter')"
                    >x</Button
                  >
                </template>
              </InputField>
            </b-col>
            <b-col cols="12" md="6" class="px-2 py-2">
              <InputField
                v-model="filtersForm.customerName"
                name="customerNameFilter"
                type="text"
                :placeholder="$t('SEARCH_BY_NAME')"
                id="customerNameFilter"
                size="small"
                :label="$t('FORM.NAME')"
                labelHidden
                v-validate="'required|min:3'"
                :data-vv-as="$t('FORM.NAME')"
                data-vv-scope="formSearchCustomer"
                data-vv-validate-on="querySearchCustomer"
                :error="errors.first('formSearchCustomer.customerNameFilter')"
                @change="querySearchCustomer"
              >
                <template slot="suffix">
                  <Button
                    variant="ghost"
                    size="small"
                    @click="clearFilter('customerNameFilter')"
                    >x</Button
                  >
                </template>
              </InputField>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          cols="7"
          class="d-flex align-items-center justify-content-end badge-group px-2"
        >
          <Group
            margin="small"
            class="d-flex align-items-center py-2 flex-wrap"
          >
            <div class="badge-group_title align-self-center">
              {{ $t('BACK_OFFICE_ORDERS.FILTER_BY_STATUS') }}:
            </div>
            <div class="d-flex badge-group_badge align-items-center flex-wrap">
              <Badge
                v-for="status in filterStatusOptions"
                :key="status.key"
                :active="isFilterActive(status)"
                :variant="orderStatusVariant(status.value)"
                clickable
                role="button"
                tabindex="0"
                @click="
                  handleFilterClick(status);
                  toBackOfficeBundleOrdersActive(status);
                "
              >
                {{ $t(`ORDER_STATUSES.${status.value}`) }}
              </Badge>
            </div>
          </Group>
        </b-col>
      </b-row>
    </Margins>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_BUNDLE_ORDERS } from '@/types';
import { Badge, Group, InputField, Margins, Button } from '@/components';
import { constants } from '@/mixins';
import { createQueryObject } from '@/utils';

export default {
  name: 'BackOfficeBundleOrdersFilter',
  mixins: [constants],
  components: {
    Badge,
    Group,
    InputField,
    Margins,
    Button,
  },
  data() {
    return {
      filterStatuses: this.filterStatusesFromBundleOrders,
      filtersForm: {
        customerName: this.$route.query.customerName || '',
        referenceNumber: this.$route.query.referenceNumber || '',
      },
      customerNameFilter: '',
      referenceNumberFilter: '',
    };
  },
  props: {
    filterStatusesFromBundleOrders: Array,
  },
  async mounted() {
    this.getFilteredBundles();
  },
  methods: {
    ...mapActions([GET_BUNDLE_ORDERS]),

    getFilteredBundles() {
      this.GET_BUNDLE_ORDERS(this.queryData);
    },
    orderStatusVariant(status, decision) {
      if (decision === this.DECISION_TYPES.DENIED) {
        return 'error';
      }

      switch (status) {
        case this.ORDER_STATUSES.CANCELLED:
        case this.ORDER_STATUSES.DENIED:
          return 'error';
        case this.ORDER_STATUSES.PENDING:
        case this.ORDER_STATUSES.SUBMITTED:
        case this.ORDER_STATUSES.AWAITING_RENEWAL:
          return 'info';
        case this.ORDER_STATUSES.COMPLETED:
        case this.ORDER_STATUSES.READY_TO_RENEW:
          return 'success';
        case this.ORDER_STATUSES.HANDED_OVER:
        case this.ORDER_STATUSES.RENEWED:
          return 'done';
        default:
      }
    },
    findOrdersReferenceNumber() {
      this.GET_BUNDLE_ORDERS({
        referenceNumber: this.filtersForm.referenceNumber,
      });
    },
    findOrdersCustomer() {
      this.GET_BUNDLE_ORDERS({ customerName: this.filtersForm.customerName });
    },

    async querySearchReferenceNumber() {
      const valid = await this.$validator.validate(
        'formSearchReferenceNumber.*'
      );

      if (!valid) {
        return false;
      }
      this.findOrdersReferenceNumber();
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { referenceNumber: this.filtersForm.referenceNumber },
      });

      await this.$validator.reset();
    },
    async querySearchCustomer() {
      const valid = await this.$validator.validate('formSearchCustomer.*');
      if (!valid) {
        return false;
      }
      this.findOrdersCustomer();
      this.$router.replace({
        ...this.$router.currentRoute,
        query: { customerName: this.filtersForm.customerName },
      });
      await this.$validator.reset();
    },
    clearFilter(name) {
      if (name === 'referenceNumberFilter') {
        this.filtersForm.referenceNumber = '';
      } else {
        this.filtersForm.customerName = '';
      }
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {},
      });

      this.GET_BUNDLE_ORDERS({});
    },
    orderStatus(item) {
      let status = item.status;
      let decision = item.decision;

      if (
        decision === this.DECISION_TYPES.DENIED ||
        status === this.DECISION_TYPES.DENIED
      ) {
        item.filterStatus = this.DECISION_TYPES.DENIED;
        return this.DECISION_TYPES.DENIED;
      }

      const statusType = this.ORDER_STATUSES[status];
      item.filterStatus = statusType;

      return statusType;
    },
    handleFilterClick(status) {
      if (this.filterStatuses.includes(status.value)) {
        this.filterStatuses = this.filterStatuses.filter(
          (s) => s !== status.value
        );
      } else {
        this.filterStatuses = this.filterStatuses.concat([status.value]);
      }
    },
    toBackOfficeBundleOrdersActive(status) {
      this.$emit('status-active', {
        inActive: this.filterStatuses.includes(status.value),
        statusType: status.value,
      });
    },
    isFilterActive(status) {
      return this.filterStatuses.includes(status.value);
    },
  },
  computed: {
    ...mapState(['bundleOrders', 'isLoading']),
    filterStatusOptions() {
      const statuses = [
        ...new Set(this.bundleOrders.map((item) => this.orderStatus(item))),
      ];

      return statuses.map((status) => {
        const key = status.toUpperCase();

        if (key === this.DECISION_TYPES.DENIED) {
          return {
            value: key,
            label: this.$t(`DECISION_TYPES.${this.DECISION_TYPES.DENIED}`),
          };
        }
        return {
          value: key,
          label: this.$t(`ORDER_STATUSES.${key}`),
        };
      });
    },
    queryData() {
      const { itemId, ...rest } = this.filtersForm;
      return createQueryObject({
        id: itemId,
        ...rest,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-group {
  padding: rem(10px);
  &_title {
    font-size: $font-size-small;
  }
  &_badge {
    max-width: rem(284px);
    .badge + .badge {
      margin-bottom: rem(4px);
      margin-left: rem(4px);
    }
  }
}
</style>
