<template>
  <Container size="full" class="p-0">
    <b-row align-v="center" no-gutters>
      <b-col class="text-center text-md-left" md="6" order="last" order-md="0">
        <Margins>
          <h2 class="mx-md-5">
            {{ $t('MARKETING.FOR_ALL_OCCASIONS.TITLE') }}
          </h2>
          <p class="text-small mx-md-5">
            {{ $t('MARKETING.FOR_ALL_OCCASIONS.TEXT') }}
          </p>
          <Separator class="d-md-none" size="x-small" />
        </Margins>
      </b-col>
      <b-col cols="auto" md="6">
        <figure>
          <img
            alt="Clocks for all occasions"
            loading="lazy"
            src="@/assets/images/clocks-for-all-occasions.jpg"
          />
        </figure>
        <Separator class="d-md-none" size="medium" />
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Margins, Separator } from '@/components';

export default {
  name: 'MarketingSectionTwo',
  components: {
    Container,
    Margins,
    Separator,
  },
};
</script>
