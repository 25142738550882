export const RESET_STORE = 'RESET_STORE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const IS_LOADING = 'IS_LOADING';
export const HAS_ERROR = 'HAS_ERROR';
export const NOTIFICATION = 'NOTIFICATION';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const GET_IS_PHYSICAL_STORE = 'GET_IS_PHYSICAL_STORE';
export const SET_IS_PHYSICAL_STORE = 'SET_IS_PHYSICAL_STORE';

export const GET_STORES = 'GET_STORES';
export const SET_STORES = 'SET_STORES';

export const GET_BUNDLES = 'GET_BUNDLES';
export const GET_RENEWABLE_BUNDLE = 'GET_RENEWABLE_BUNDLE';
export const SET_BUNDLES = 'SET_BUNDLES';
export const SET_SERVICES = 'SET_SERVICES';

export const SET_SELECTED_BUNDLE = 'SET_SELECTED_BUNDLE';

export const ASK_QUESTION = 'ASK_QUESTION';

export const GET_LOGIN_INFO = 'GET_LOGIN_INFO';
export const SET_LOGIN_INFO = 'SET_LOGIN_INFO';

export const SET_LOAN_APPLICATION_DATA = 'SET_LOAN_APPLICATION_DATA';
export const SUBMIT_LOAN_APPLICATION = 'SUBMIT_LOAN_APPLICATION';
export const SUBMIT_RENEWAL_APPLICATION = 'SUBMIT_RENEWAL_APPLICATION';
export const HAS_LOAN_APPLICATION_ERROR = 'HAS_LOAN_APPLICATION_ERROR';
export const GET_APPLICATION_STATUS = 'GET_APPLICATION_STATUS';
export const SET_APPLICATION_STATUS = 'SET_APPLICATION_STATUS';
export const SET_LOAN_APPLICATION_DECISION = 'SET_LOAN_APPLICATION_DECISION';

export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';

export const GET_INDIVIDUALIZED_TERMS = 'GET_INDIVIDUALIZED_TERMS';
export const SET_INDIVIDUALIZED_TERMS = 'SET_INDIVIDUALIZED_TERMS';

export const SIGN_WITH_BANK_ID = 'SIGN_WITH_BANK_ID';
export const SET_SIGNING_DATA = 'SET_SIGNING_DATA';

export const FINALIZE_ACCOUNT_REGISTRATION = 'FINALIZE_ACCOUNT_REGISTRATION';

export const SAVE_BUNDLE_ORDER_DELIVERY_INFO =
  'SAVE_BUNDLE_ORDER_DELIVERY_INFO';
export const SET_BUNDLE_ORDER_DELIVERY_INFO = 'SET_BUNDLE_ORDER_DELIVERY_INFO';

export const IS_BUNDLE_ORDER_COMPLETE = 'IS_BUNDLE_ORDER_COMPLETE';
export const SET_SIGNATURE_STATUS = 'SET_SIGNATURE_STATUS';

// Back office
export const GET_BUNDLE_ORDERS = 'GET_BUNDLE_ORDERS';
export const SET_BUNDLE_ORDERS = 'SET_BUNDLE_ORDERS';
export const GET_BUNDLE_ORDER_DETAILS = 'GET_BUNDLE_ORDER_DETAILS';
export const GET_BUNDLE_ORDER_PRODUCTS = 'GET_BUNDLE_ORDER_PRODUCTS';
export const GET_RETURNED_ORDER_PRODUCTS = 'GET_RETURNED_ORDER_PRODUCTS';
export const GET_RENEWABLE_ORDER = 'GET_RENEWABLE_ORDER';
export const IS_RENEWABLE_ORDER = 'IS_RENEWABLE_ORDER';
export const SET_ORDER_HANDED_OVER = 'SET_ORDER_HANDED_OVER';
export const SET_BACK_OFFICE_LOGGED_IN = 'SET_BACK_OFFICE_LOGGED_IN';
export const SET_BACK_OFFICE_USER = 'SET_BACK_OFFICE_USER';
export const BACK_OFFICE_LOGIN = 'BACK_OFFICE_LOGIN';
export const BACK_OFFICE_LOGOUT = 'BACK_OFFICE_LOGOUT';
export const ACCEPT_INVITATION = 'ACCEPT_INVITATION';
export const BACK_OFFICE_PASSWORD_LOST = 'BACK_OFFICE_PASSWORD_LOST';
export const BACK_OFFICE_PASSWORD_RESET = 'BACK_OFFICE_PASSWORD_RESET';
