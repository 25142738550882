<template>
  <div>
    <div class="products-card">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_NAME') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_SERIAL') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_CODE') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_PRICE') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.STATUS') }}</th>
            <th v-if="isRedactable"></th>
          </tr>
        </thead>
        <tbody>
          <template v-if="products && products.length">
            <tr v-for="(product, index) in products" :key="product.code">
              <td>{{ index + 1 }}</td>
              <td class="text-center">
                <strong>{{ product.name }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.serial }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.code }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.price }}</strong>
              </td>
              <td class="text-center">
                <template v-if="product.delivered">
                  <strong>{{ statusDelivered }}</strong>
                </template>
              </td>
              <td v-if="isRedactable" class="text-center">
                <Button
                  variant="text"
                  @click="removeItems(product.id)"
                  :title="$t('CLOSE_DETAILS')"
                >
                  <CloseSVG class="icon" />
                </Button>
              </td>
            </tr>
          </template>
          <template v-if="returnedProducts && returnedProducts.length">
            <tr
              v-for="product in returnedProducts"
              :key="product.code"
              class="text-grey"
            >
              <td></td>
              <td class="text-center">
                <strong>{{ product.name }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.serial }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.code }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ product.price }}</strong>
              </td>
              <td class="text-center">
                <strong>{{ statusReturned }}</strong>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <div class="related-bundle_table">
      <BackOfficeBundleRenewalTable :renewed-bundle="renewedBundle" />
    </div>
  </div>
</template>

<script>
import { Button } from '@/components';

import CloseSVG from '@/assets/images/times-circle-regular.svg';
import BackOfficeBundleRenewalTable from './BackOfficeBundleRenewalTable.vue';

export default {
  name: 'BackOfficeRenewedBundleOrdersProducts',
  components: { Button, CloseSVG, BackOfficeBundleRenewalTable },
  props: {
    products: Array,
    renewedBundle: Object,
    returnedProducts: Array,
    isRedactable: Boolean,
  },
  methods: {
    removeItems(index) {
      this.$emit('remove-item', index);
    },
  },
  computed: {
    statusReturned() {
      return this.$t(`BACK_OFFICE_ORDERS.RETURNED`);
    },
    statusDelivered() {
      return this.$t(`BACK_OFFICE_ORDERS.DELIVERED`);
    },
  },
};
</script>

<style lang="scss" scoped>
.products-card {
  width: 80%;
  margin: 0 auto;
  border: rem(1px) solid $color-grey-border;
  padding: rem(32px);
  background: $color-white;

  &_header {
    padding-bottom: rem(36px);
  }
}
.related-bundle {
  &_table {
    width: 80%;
    margin: 0 auto;
    padding: rem(16px);
  }
}
</style>
