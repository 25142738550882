var render = function () {
var _obj, _obj$1, _obj$2, _obj$3, _obj$4, _obj$5;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(_vm.to ? 'router-link' : _vm.href ? 'a' : 'button',{tag:"component",class:[
    'button',
    ( _obj = {}, _obj[("button--" + _vm.variant)] = !!_vm.variant, _obj ),
    ( _obj$1 = {}, _obj$1[("button--" + _vm.size)] = !!_vm.size, _obj$1 ),
    ( _obj$2 = {}, _obj$2[("button--color-" + _vm.color)] = !!_vm.color, _obj$2 ),
    ( _obj$3 = {}, _obj$3[("button--border-" + _vm.border)] = !!_vm.border, _obj$3 ),
    ( _obj$4 = {}, _obj$4['button--block'] = _vm.block, _obj$4 ),
    ( _obj$5 = {}, _obj$5['button--active'] = _vm.active, _obj$5 ) ],attrs:{"to":_vm.to,"href":_vm.href,"target":_vm.target,"rel":_vm.target === '_blank' ? 'noopener noreferrer' : null,"type":_vm.isLink ? null : _vm.type,"disabled":_vm.disabled || _vm.loading},on:{"click":function($event){return _vm.$emit('click', $event)}}},[_c('span',{staticClass:"button__content"},[_vm._t("icon"),_c('span',{staticClass:"button__text"},[_vm._t("default")],2),(_vm.loading)?_c('LoadingSVG',{staticClass:"icon"}):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }