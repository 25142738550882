<template>
  <div
    :class="[
      'badge',
      { [`badge--${variant}`]: !!variant },
      { [`badge--clickable`]: clickable },
      { [`badge--active`]: active },
    ]"
    @click="clickable ? $emit('click') : null"
  >
    <CheckSVG v-if="clickable && active" class="icon badge__icon" />
    <span><slot /></span>
  </div>
</template>

<script>
import CheckSVG from '@/assets/images/check-solid.svg';

export default {
  name: 'Badge',
  components: {
    CheckSVG,
  },
  props: {
    variant: {
      validator: function (value) {
        return ['default', 'success', 'error', 'info', 'done'].includes(value);
      },
      default: 'default',
    },
    clickable: Boolean,
    active: Boolean,
  },
};
</script>

<style lang="scss">
.badge {
  display: inline-flex;
  background: $color-grey-dark;
  border-radius: 99em;
  color: $color-white;
  font-size: $font-size-extra-small;
  line-height: 1;
  padding: rem(6px) 0.5rem;
  text-transform: uppercase;
}

.badge--success {
  background: $color-success;
}

.badge--error {
  background: $color-error;
}

.badge--info {
  background: $color-info;
}

.badge--done {
  background: $color-done;
}

.badge--clickable {
  cursor: pointer;

  &:hover {
    box-shadow: $elevation;
  }
}

.badge--active {
  background: $color-black;
}

.badge__icon {
  margin-right: 0.5rem;
}
</style>
