var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('hr',{class:[
    'separator',
    { 'separator--line': _vm.hasLine },
    ( _obj = {}, _obj[("separator--" + _vm.size)] = !!_vm.size, _obj ),
    ( _obj$1 = {}, _obj$1[("separator--line-thickness-" + _vm.thickness)] = !!_vm.thickness, _obj$1 ),
    ( _obj$2 = {}, _obj$2[("separator--line-color-" + _vm.color)] = !!_vm.color, _obj$2 ) ]})}
var staticRenderFns = []

export { render, staticRenderFns }