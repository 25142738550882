<template>
  <Container class="marketing-texts">
    <b-row align-h="center">
      <b-col md="9">
        <Margins class="text-center">
          <h2 class="h1 text-transform-none">
            {{ $t('MARKETING.TEXTS.TITLE') }}
          </h2>
          <p
            class="marketing-texts__description text-transform-none text-small margins__double"
          >
            {{ $t('MARKETING.TEXTS.DESCRIPTION') }}
          </p>

          <b-row class="margins__triple">
            <b-col
              cols="4"
              md="2"
              offset-md="2"
              class="marketing-texts__counter p-md-0"
            >
              <div
                class="marketing-texts__icon-wrapper d-flex align-items-center"
              >
                <ValjPaketSVG class="marketing-texts__icon" />
                <p class="h3 marketing-texts__text">
                  {{ $t('HEADER.SELECT_BUNDLE') }}
                </p>
              </div>
            </b-col>
            <b-col
              cols="4"
              md="2"
              offset-md="1"
              class="marketing-texts__counter p-md-0"
            >
              <div
                class="marketing-texts__icon-wrapper d-flex align-items-center"
              >
                <AnsokSVG class="marketing-texts__icon" />
                <p class="h3 marketing-texts__text">{{ $t('HEADER.APPLY') }}</p>
              </div>
            </b-col>
            <b-col
              cols="4"
              md="2"
              offset-md="1"
              class="marketing-texts__counter p-md-0"
            >
              <div
                class="marketing-texts__icon-wrapper d-flex align-items-center"
              >
                <SigneraSVG class="marketing-texts__icon" />
                <p class="h3 marketing-texts__text">{{ $t('HEADER.SIGN') }}</p>
              </div>
            </b-col>
          </b-row>
        </Margins>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Margins } from '@/components';
import { constants } from '@/mixins';
import ValjPaketSVG from '@/assets/images/valj-paket.svg';
import AnsokSVG from '@/assets/images/ansok.svg';
import SigneraSVG from '@/assets/images/signera.svg';

export default {
  name: 'MarketingTexts',
  mixins: [constants],
  components: {
    Container,
    Margins,
    ValjPaketSVG,
    AnsokSVG,
    SigneraSVG,
  },
};
</script>

<style lang="scss">
.marketing-texts__description {
  line-height: 1.375rem;
}

.marketing-texts__counter {
  counter-increment: icon-counter;
}

.marketing-texts__icon-wrapper {
  height: 75px;
  position: relative;
  margin: auto;

  .marketing-texts__text {
    font-size: 0.85rem;
    line-height: 1.2;
    text-transform: lowercase;
    width: -webkit-fill-available;
  }

  &::after {
    content: counter(icon-counter);
    position: absolute;
    font-family: $font-family-header;
    font-size: 6.5rem;
    line-height: 6.5rem;
    color: $color-primary;
    opacity: 0.1;
    top: 0;
    right: 0;
  }

  @include min-width(sm) {
    height: 200px;

    .marketing-texts__icon {
      max-width: 100px;
    }

    .marketing-texts__text {
      font-size: 1.5rem;
      line-height: 1;
    }

    &::after {
      font-size: 14.5rem;
      line-height: 14.5rem;
    }
  }

  @include min-width(md) {
    height: 150px;

    .marketing-texts__icon {
      max-width: 100px;
      padding-right: 15px;
    }

    .marketing-texts__text {
      font-size: 1.5rem;
      line-height: 1;
    }

    &::after {
      font-size: 10.5rem;
      line-height: 11.5rem;
    }
  }
}
</style>
