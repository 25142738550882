var render = function () {
var _obj, _obj$1, _obj$2;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.haveItems)?_c('ul',{class:[
    'list',
    ( _obj = {}, _obj[("list--" + _vm.variant)] = !!_vm.variant, _obj ),
    ( _obj$1 = {}, _obj$1[("list--" + _vm.size)] = !!_vm.size, _obj$1 ),
    ( _obj$2 = {}, _obj$2[("list--icon-" + _vm.icon)] = !!_vm.icon, _obj$2 ) ]},_vm._l((_vm.items),function(item,index){
  var _obj;
return _c('li',{key:index,staticClass:"list__item",class:( _obj = {}, _obj["m-0"] = !!_vm.noMargin, _obj ),domProps:{"innerHTML":_vm._s(item)}})}),0):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }