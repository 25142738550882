<template>
  <footer class="footer text-center text-md-left">
    <Container>
      <b-row align-h="between">
        <b-col md="auto">
          <h5 class="footer__contact">{{ $t('FOOTER.CONTACT_US') }}</h5>
          <p v-html="phoneMessage" />
          <a :href="`mailto:${$t('FOOTER.EMAIL')}`" class="text-black">
            {{ $t('FOOTER.EMAIL') }}
          </a>
          <p>{{ $t('FOOTER.OPENING_TIMES') }}</p>
          <Separator size="small" />
          <div class="footer__social">
            <a
              href="https://www.facebook.com/stjarnurmakarna/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="stjarnurmakarna"
                class="footer__social-media-icon"
                loading="lazy"
                src="@/assets/images/facebook.png"
              />
            </a>
            <a
              href="https://www.instagram.com/stjarnurmakarna/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="stjarnurmakarna"
                class="footer__social-media-icon"
                loading="lazy"
                src="@/assets/images/instagram.png"
              />
            </a>
          </div>
        </b-col>
        <b-col md="auto" class="">
          <img
            src="@/assets/images/auktoriserad_symbol2.jpg"
            alt="stjarnurmakarna"
            height="126"
            width="470"
            loading="lazy"
            class="center-image"
          />
          <Separator size="small" />
          <a
            class="footer__to-website text-black"
            href="https://stjarnurmakarna.se/"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t('FOOTER.TO_WEBSITE') }}</a
          >
        </b-col>
      </b-row>
    </Container>
  </footer>
</template>

<script>
import { Separator } from '@/components';
import Container from '@/components/Container';

export default {
  name: 'Footer',
  components: {
    Container,
    Separator,
  },
  computed: {
    phoneMessage() {
      const supportPhone = '08-6294400';
      const phoneNumber = supportPhone.replace(/\s|-/g, '');

      return this.$t('FOOTER.PHONE', [
        `<a class="text-black" href="tel:${phoneNumber}">${supportPhone}</a>`,
      ]);
    },
  },
};
</script>

<style lang="scss">
.footer {
  background: $color-white;
  padding-top: 3rem;
  padding-bottom: 3rem;
  line-height: 1.5rem;

  &__contact {
    line-height: 3rem;
  }

  &__social > * + * {
    margin-left: 0.5rem;
  }

  &__social-media-icon {
    display: inline-block;
    width: 1.55rem;
  }

  &__to-website {
    font-family: $font-family-header;
    text-decoration: underline;

    @include min-width(md) {
      text-align: right;
      display: block;
    }
  }
}
</style>
