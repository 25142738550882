<template>
  <table class="table">
    <thead>
      <tr>
        <th>{{ $t('FORM.NAME') }}</th>
        <th>{{ $t('FORM.MOBILE') }}</th>
        <th>{{ $t('FORM.EMAIL') }}</th>
        <th>{{ $t('FORM.ADDRESS') }}</th>
        <th>{{ $t('FORM.ZIP_CODE') }}</th>
        <th>{{ $t('FORM.CITY') }}</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td
          class="text-center"
          v-for="(field, index) in orderDetails"
          :key="index"
        >
          {{ field || '' }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'BackOfficeBundleOrderDetails',
  props: {
    orderDetails: Object,
  },
};
</script>
