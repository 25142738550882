<template>
  <div class="back-office-bundle-orders">
    <Margins>
      <Container>
        <BackOfficeBundleOrdersFilter
          @status-active="filteredStatusesHandle"
          :filter-statuses-from-bundle-orders="this.filterStatuses"
        />
      </Container>

      <b-row v-if="isLoading" align-h="center">
        <b-col cols="auto">
          <Loader :text="$t('LOADING')" />
        </b-col>
      </b-row>
      <BackOfficeBundleOrdersTable
        :filteredOrders="this.getFilteredOrders"
        v-else
      />
    </Margins>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { Loader, Margins, Container } from '@/components';
import { constants } from '@/mixins';
import BackOfficeBundleOrdersFilter from '@/containers/BackOffice/BackOfficeBundleOrdersFilter';
import BackOfficeBundleOrdersTable from '@/containers/BackOffice/BackOfficeBundleOrdersTable';

export default {
  name: 'BackOfficeBundleOrders',
  mixins: [constants],
  components: {
    BackOfficeBundleOrdersTable,
    BackOfficeBundleOrdersFilter,
    Loader,
    Margins,
    Container,
  },
  data() {
    return {
      filterStatuses: ['COMPLETED', 'HANDED_OVER'],
    };
  },
  methods: {
    filteredStatusesHandle({ inActive, statusType }) {
      if (inActive) {
        this.filterStatuses.push(statusType);
      } else if (!inActive) {
        this.filterStatuses.splice(this.filterStatuses.indexOf(statusType), 1);
      }
    },
  },
  computed: {
    ...mapState(['bundleOrders', 'isLoading']),
    getFilteredOrders() {
      if (this.filterStatuses.length === 0) {
        return this.bundleOrders;
      }
      return this.bundleOrders.filter((order) => {
        if (order.decision === 'DENIED') {
          return this.filterStatuses.includes('DENIED');
        }
        return this.filterStatuses.includes(order.status);
      });
    },
  },
};
</script>
