<template>
  <header
    class="header"
    :class="{ ['has-line']: !showMarketingItems || !isMarketingView }"
  >
    <Container>
      <b-row>
        <b-col offset="2" offset-md="0">
          <router-link
            :to="ROUTES.HOME.path"
            class="header__main-link justify-content-center justify-content-md-start"
          >
            <LogoSVG class="header__logo" />
          </router-link>
        </b-col>
        <b-col cols="2" md="auto" align-self="center">
          <MenuWrapper>
            <ul
              class="header__list"
              :class="{ 'header__list--simple': showMarketingItems }"
            >
              <li
                v-for="item in headerItems"
                :key="item.label"
                class="header__list-item"
                :class="{ 'text-uppercase': !showMarketingItems }"
              >
                <router-link
                  :to="item.to"
                  :exact="item.exact"
                  :event="item.event"
                  :class="item.class"
                  :title="item.label"
                >
                  {{ item.label }}
                </router-link>
              </li>
              <li v-if="multipleLanguages" class="header__list-item">
                <LanguageSwitcher />
              </li>
              <li
                v-if="isPhysicalStore && backOfficeLoggedIn"
                class="header__list-item"
              >
                <Logout />
              </li>
            </ul>
          </MenuWrapper>
        </b-col>
      </b-row>
    </Container>
    <Container v-if="isPhysicalStore && backOfficeLoggedIn">
      <ul class="header__list header__list--store">
        <li
          v-for="item in headerStoreItems"
          :key="item.label"
          class="header__list-item"
        >
          <a
            v-if="item.external"
            :href="item.to"
            target="_blank"
            rel="noopener noreferrer"
            :class="item.class"
            :title="item.label"
          >
            {{ item.label }}
          </a>
          <router-link
            v-else
            :to="item.to"
            :exact="item.exact"
            :event="item.event"
            :class="item.class"
            :title="item.label"
          >
            {{ item.label }}
          </router-link>
        </li>
      </ul>
    </Container>
  </header>
</template>

<script>
import { Container } from '@/components';
import MenuWrapper from './MenuWrapper.vue';
import { mapState } from 'vuex';
import { constants } from '@/mixins';
import LanguageSwitcher from './LanguageSwitcher.vue';
import Logout from './Logout';
import LogoSVG from '@/assets/images/logo.svg';

export default {
  name: 'Header',
  mixins: [constants],
  components: {
    Logout,
    Container,
    LanguageSwitcher,
    LogoSVG,
    MenuWrapper,
  },
  props: {
    showMarketingItems: Boolean,
    isMarketingView: Boolean,
  },
  computed: {
    ...mapState([
      'currentStep',
      'isLoading',
      'isPhysicalStore',
      'backOfficeLoggedIn',
    ]),
    headerItems() {
      if (this.showMarketingItems) {
        return [
          {
            label: this.$t('HEADER_MARKETING.PRICE_EXAMPLE'),
            to: this.ROUTES.SUBSCRIPTION.path,
            exact: true,
            class: ['header__link', 'is-valid'],
          },
          {
            label: this.$t('HEADER_MARKETING.FAQ'),
            to: this.ROUTES.FAQ.path,
            exact: true,
            class: ['header__link', 'is-valid'],
          },
        ];
      }

      return [
        {
          label: `1. ${this.$t('HEADER.SELECT_BUNDLE')}`,
          to: this.ROUTES.SUBSCRIPTION.path,
          exact: true,
          event: this.isLoading ? '' : 'click',
          class: [
            'header__link',
            { 'is-valid': this.currentStep >= this.STEPS.select },
            { 'is-disabled': this.isLoading },
          ],
        },
        {
          label: `2. ${this.$t('HEADER.APPLY')}`,
          to: this.ROUTES.APPLY.path,
          exact: false,
          event:
            this.currentStep >= this.STEPS.apply && !this.isLoading
              ? 'click'
              : '',
          class: [
            'header__link',
            { 'is-valid': this.currentStep >= this.STEPS.apply },
            {
              'is-disabled':
                this.currentStep < this.STEPS.apply || this.isLoading,
            },
          ],
        },
        {
          label: `3. ${this.$t('HEADER.SIGN')}`,
          to: this.ROUTES.DECISION_SIGN.path,
          exact: false,
          event: this.currentStep >= this.STEPS.sign ? 'click' : '',
          class: [
            'header__link',
            { 'is-valid': this.currentStep >= this.STEPS.sign },
            { 'is-disabled': this.currentStep < this.STEPS.sign },
          ],
        },
      ];
    },
    headerStoreItems() {
      return [
        {
          label: this.$t('BACK_OFFICE'),
          to: this.ROUTES.BACK_OFFICE.path,
          exact: false,
          event: 'click',
          class: ['header__link'],
        },
        {
          label: this.$t('NEW_APPLICATION'),
          to: this.ROUTES.SUBSCRIPTION.path,
          exact: false,
          event: 'click',
          class: ['header__link'],
        },
      ];
    },
    multipleLanguages() {
      return this.STORE.availableLocales.length > 1;
    },
  },
};
</script>

<style lang="scss">
.header {
  background: $color-white;
  z-index: 11;
  position: sticky;
  top: 0;
}

.header.has-line {
  border-bottom: 1px solid $color-grey-border;
}

.header__main-link {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 1rem 0;
}

.header__logo {
  height: 2.5rem;
  width: auto;
}

.header__list {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  height: 100%;
  justify-content: flex-end;
}

.header__list--store {
  border-top: rem(1px) solid $color-grey-border;
  justify-content: flex-start;
}

.header__list-item {
  display: flex;
  align-items: center;
  padding-left: rem(20px);
  padding-right: rem(20px);
}

.header__link {
  display: flex;
  align-items: center;
  color: $color-grey-dark;
  border: rem(3px) solid $color-white;
  border-left: 0;
  border-right: 0;
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size-base-rem;
  text-align: center;
  white-space: nowrap;

  @include min-width(md) {
    font-size: $font-size-medium;
  }

  &.is-valid {
    color: $color-grey-darker;
  }

  &.is-disabled {
    cursor: default;

    &:hover {
      text-decoration: none;
    }
  }

  &.router-link-active {
    color: $color-black;
    border-bottom-color: $color-black;
  }

  .header__list--store & {
    height: rem(40px);
  }
}
</style>
