<template>
  <div class="home">
    <MarketingHero />
    <Separator size="large" />
    <MarketingTexts />
    <Separator size="large" />
    <MarketingSectionOne />
    <MarketingSectionTwo />
    <MarketingSectionThree />
    <Separator size="large" />
    <MarketingSectionFour />
    <Separator size="large" />
    <MarketingSectionFive />
    <Separator size="large" />
    <MarketingSectionSix />
    <Separator size="small" />
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import { RESET_STORE, SET_SIGNATURE_STATUS } from '@/types';
import {
  MarketingHero,
  MarketingTexts,
  MarketingSectionOne,
  MarketingSectionTwo,
  MarketingSectionThree,
  MarketingSectionFour,
  MarketingSectionFive,
  MarketingSectionSix,
} from '@/containers';
import { Separator } from '@/components';

export default {
  name: 'Home',
  components: {
    MarketingHero,
    MarketingTexts,
    MarketingSectionOne,
    MarketingSectionTwo,
    MarketingSectionThree,
    MarketingSectionFour,
    MarketingSectionFive,
    MarketingSectionSix,
    Separator,
  },
  mounted() {
    this.RESET_STORE();
    this.SET_SIGNATURE_STATUS(null);
  },
  methods: {
    ...mapMutations([RESET_STORE, SET_SIGNATURE_STATUS]),
  },
};
</script>
<style lang="scss" scoped>
.home {
  margin-top: -5rem;
}
</style>
