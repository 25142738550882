import { API_BASE_URL, ROUTES } from '@/constants';
import { getUrlParams } from '@/utils';
import store from '@/store';
import { BACK_OFFICE_LOGOUT } from '@/types';
import qs from 'qs';

export const makeFetch = async (url, additionalOptions) => {
  const defaultOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const options = {
    ...defaultOptions,
    ...additionalOptions,
  };

  const response = await fetch(`${API_BASE_URL}${url}`, options);

  const body = await getBody(response);

  if (!response.ok) {
    let error = new Error(body.message);
    error.status = body.status;
    error.code = body.errorCode;

    if (error.status === 401 && url !== ROUTES.BACK_OFFICE_LOGIN.path) {
      store.dispatch(BACK_OFFICE_LOGOUT);
    } else {
      throw error;
    }
  }

  return body;
};

const getBody = async (response) => {
  try {
    return await response.json();
  } catch (error) {
    return await response;
  }
};

class ApiService {
  static isStore() {
    return makeFetch(`/host/is-store`);
  }

  static getBundles() {
    return makeFetch(`/bundle/find-all`);
  }

  static getServices() {
    return makeFetch(`/bundle/find-all-services`);
  }

  static getRenewableBundle(referenceNumber) {
    return makeFetch(`/bundle/find-renewable-bundle/${referenceNumber}`);
  }

  static getBundleOrderDetails(referenceNumber) {
    return makeFetch(`/bundle-order/${referenceNumber}/details`);
  }

  static getBundleOrderProducts(referenceNumber) {
    return makeFetch(`/bundle-order/${referenceNumber}/products`);
  }

  static getReturnedOrderProducts(referenceNumber) {
    return makeFetch(`/bundle-order/${referenceNumber}/returned-products`);
  }

  static getRenewableOrder(referenceNumber) {
    return makeFetch(`/bundle-order/${referenceNumber}/renewable-order`);
  }

  static isRenewableOrder(referenceNumber) {
    return makeFetch(`/bundle-order/is-renewable/${referenceNumber}`);
  }

  static getStores() {
    return makeFetch(`/store`);
  }

  static submitLoanApplication(data) {
    return makeFetch(`/application/submit`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static submitRenewalApplication(data) {
    return makeFetch(`/application/submit-renewal`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static getApplicationStatus(referenceNumber) {
    return makeFetch(`/application/get-status/${referenceNumber}`);
  }

  static createAccount(referenceNumber) {
    return makeFetch(`/application/create-account/${referenceNumber}`, {
      method: 'POST',
    });
  }

  static getIndividualizedTerms(params) {
    const parameters = getUrlParams(params);

    return makeFetch(`/application/get-individualized-terms?${parameters}`);
  }

  static createSigningSession(data) {
    return makeFetch(`/application/create-signing-session`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static finalizeAccountRegistration(data) {
    return makeFetch(`/application/finalize-account-registration`, {
      method: 'PUT',
      body: JSON.stringify(data),
    });
  }

  static getSignatureStatus(signingReference) {
    return makeFetch(`/application/get-signature-status/${signingReference}`);
  }

  static saveBundleOrderDeliveryInfo(data) {
    return makeFetch(`/bundle-order/save-delivery-info`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static isBundleOrderComplete(data) {
    return makeFetch(`/bundle-order/complete`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static askQuestion(data) {
    return makeFetch(`/info/ask-question`, {
      method: 'POST',
      body: JSON.stringify(data),
    });
  }

  static getBundleOrders(data) {
    const queryParams = Object.fromEntries(
      Object.entries(data).map(([key, value]) => [key, value || ''])
    );
    const query = new URLSearchParams(queryParams);
    return makeFetch(`/bundle-order/find-all?${query.toString()}`);
  }

  static changeOrderStatusToHandedOver(data) {
    return makeFetch(`/bundle-order/hand-over`, {
      method: 'PATCH',
      body: JSON.stringify(data),
    });
  }

  static login(data) {
    return makeFetch('/login', {
      body: qs.stringify(data),
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  static logout() {
    return makeFetch('/logout');
  }

  static alive() {
    return makeFetch('/alive');
  }

  static acceptInvitation(invitationId, data) {
    return makeFetch(`/invitation/${invitationId}/accept`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static getLoginInfo() {
    return makeFetch(`/login/user`);
  }

  static requestPasswordReset(data) {
    return makeFetch(`/password-reset/request/`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }

  static resetPassword(tokenId, data) {
    return makeFetch(`/password-reset/${tokenId}/reset`, {
      body: JSON.stringify(data),
      method: 'POST',
    });
  }
}

export default ApiService;
