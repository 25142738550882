<template>
  <form
    v-if="isRenewable"
    @submit="handleSubmit"
    class="loan-application-form"
    autocomplete="off"
  >
    <InputField
      name="bot-field"
      label="Don’t fill this out if you're human"
      v-model="formData.honeypot"
      autocomplete="random"
      isHoneyPot
    />

    <Margins>
      <template v-if="FEATURE_LOGIN && !isPhysicalStore">
        <h2>
          {{ $t('HELLO') }}, {{ loginInfo.firstName }} {{ loginInfo.lastName }}!
        </h2>
      </template>

      <template v-else>
        <b-row>
          <b-col md="6">
            <InputField
              :label="`${$t('FORM.FIRST_NAME')} *`"
              name="firstName"
              autocomplete="first-name"
              v-model.trim="formData.firstName"
              :error="errors.first('firstName')"
              v-validate="{ required: true }"
              :data-vv-as="$t('FORM.VALIDATOR.FIRST_NAME')"
            />
          </b-col>
          <b-col md="6">
            <InputField
              :label="`${$t('FORM.LAST_NAME')} *`"
              name="lastName"
              autocomplete="family-name"
              v-model.trim="formData.lastName"
              :error="errors.first('lastName')"
              v-validate="{ required: true }"
              :data-vv-as="$t('FORM.VALIDATOR.LAST_NAME')"
              class="margins__mobile-md-double"
            />
          </b-col>
        </b-row>

        <InputField
          :label="`${$t('FORM.ID_NUMBER')} *`"
          name="identificationNumber"
          placeholder="00000000-0000"
          v-model.trim="formData.identificationNumber"
          :error="errors.first('identificationNumber')"
          v-validate="{
            required: true,
            regex: REGEX.SE.identificationNumber,
          }"
          :data-vv-as="$t('FORM.VALIDATOR.ID_NUMBER')"
          class="margins__double"
        />
      </template>

      <b-row class="margins__double">
        <b-col md="6">
          <InputField
            type="email"
            :label="`${$t('FORM.EMAIL')} *`"
            name="email"
            autocomplete="email"
            v-model.trim="formData.email"
            :error="errors.first('email')"
            v-validate="{ required: true, email: true }"
          />
        </b-col>
        <b-col md="6">
          <InputField
            :label="`${$t('FORM.MOBILE')} *`"
            name="mobile"
            placeholder="+46-000000000"
            autocomplete="tel"
            v-model.trim="formData.mobile"
            :error="errors.first('mobile')"
            v-validate="{
              required: true,
              regex: REGEX.SE.mobile,
            }"
            prefix="🇸🇪"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>

      <b-row class="margins__double">
        <b-col>
          <InputField
            :label="`${$t('FORM.ADDRESS')} *`"
            name="address"
            autocomplete="street-address"
            v-model.trim="formData.address"
            :error="errors.first('address')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.ADDRESS')"
          />
        </b-col>
        <b-col md="2">
          <InputField
            :label="`${$t('FORM.ZIP_CODE')} *`"
            placeholder="00000"
            name="zipCode"
            autocomplete="postal-code"
            v-model.trim="formData.zipCode"
            :error="errors.first('zipCode')"
            v-validate="{
              required: true,
              regex: REGEX.SE.postalCode,
            }"
            :data-vv-as="$t('FORM.VALIDATOR.ZIP_CODE')"
            class="margins__mobile-md-double"
          />
        </b-col>
        <b-col>
          <InputField
            :label="`${$t('FORM.CITY')} *`"
            name="city"
            autocomplete="address-level2"
            v-model.trim="formData.city"
            :error="errors.first('city')"
            v-validate="{ required: true }"
            :data-vv-as="$t('FORM.VALIDATOR.CITY')"
            class="margins__mobile-md-double"
          />
        </b-col>
      </b-row>

      <Separator hasLine size="large" />

      <Check
        :label="$t('FORM.PERSONAL_INFO_CONSENT')"
        name="personalInfoConsent"
        v-model="formData.personalInfoConsent"
        v-validate="{ required: true }"
      />

      <Check
        :label="privacyPolicyConsent"
        name="privacyConsent"
        v-model="formData.privacyConsent"
        v-validate="{ required: true }"
      />

      <div
        v-if="
          errors.first('personalInfoConsent') || errors.first('privacyConsent')
        "
        class="check__error"
      >
        {{ $t('FORM.VALIDATOR.TERMS') }}
      </div>

      <Separator size="small" />

      <Radio
        id="radio"
        :label="renewableOrderText"
        name="renewableBundleOrder"
        checked
      />

      <div class="text-center margins__triple">
        <p>{{ $t('FORM.CREDIT_CHECK_INFO') }}</p>
      </div>

      <div class="text-center">
        <Button type="submit">{{ $t('CONTINUE') }}</Button>
      </div>
    </Margins>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_RENEWABLE_BUNDLE, SET_LOAN_APPLICATION_DATA } from '@/types';
import { Button, Check, InputField, Margins, Separator } from '@/components';
import { constants } from '@/mixins';
import { getPersistedStore } from '@/utils';
import Radio from '@/components/Radio';

export default {
  name: 'renewalApplicationForm',
  mixins: [constants],
  components: {
    Radio,
    InputField,
    Margins,
    Button,
    Check,
    Separator,
  },
  props: {
    isRenewable: Boolean,
    reference: String,
  },
  data() {
    return {
      formData: {
        mobile: '+46',
        country: 'SE',
      },
    };
  },
  created() {
    const localLoanApplicationData = getPersistedStore().loanApplicationData;

    if (!localLoanApplicationData) {
      return false;
    }

    this.formData = this.removeConsents(localLoanApplicationData);
  },
  methods: {
    ...mapActions([GET_RENEWABLE_BUNDLE, SET_LOAN_APPLICATION_DATA]),
    removeConsents(object) {
      const { personalInfoConsent, privacyConsent, ...formData } = object;
      return formData;
    },
    async handleSubmit(e) {
      e.preventDefault();

      if (this.formData.honeypot) {
        return false;
      }

      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      this.selectedBundle = await this.GET_RENEWABLE_BUNDLE(this.reference);

      const clonedFormData = JSON.parse(JSON.stringify(this.formData));
      clonedFormData.zipCode = clonedFormData.zipCode.replace(/\s+/g, '');
      clonedFormData.personalInfoConsent = !!clonedFormData.personalInfoConsent;
      clonedFormData.privacyConsent = !!clonedFormData.privacyConsent;

      const data = {
        ...clonedFormData,
        ...(this.FEATURE_LOGIN &&
          !this.isPhysicalStore && { ...this.loginInfo }),
      };

      this.SET_LOAN_APPLICATION_DATA(data);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION_LOADING.name });

      this.formData = {};
      this.$validator.reset();
    },
  },
  computed: {
    ...mapState(['loginInfo', 'loanApplicationData', 'isPhysicalStore']),
    privacyPolicyConsent() {
      const locale = this.$i18n.locale.toUpperCase();
      const policyFileName = `PrivacyPolicy${locale}`;
      const termsAndCondFileName = `TermsAndConditions${locale}`;
      return `<p>${this.$t('FORM.PRIVACY_CONSENT', [
        `<a href="${window.location.origin}/documents/${termsAndCondFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
        `<a href="${window.location.origin}/documents/${policyFileName}.pdf" target="_blank" rel="noopener noreferrer">`,
        `</a>`,
      ])}</p>`;
    },
    renewableOrderText() {
      return this.$t('FORM.RENEWABLE_ORDER', [this.reference]);
    },
  },
};
</script>
