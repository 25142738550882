<template>
  <div
    :class="[
      'input',
      { 'has-error': error },
      { 'has-prefix': prefix },
      { 'has-suffix': !!$slots.suffix },
      { [`input--${size}`]: !!size },
      { 'is-honey-pot': isHoneyPot },
      { 'is-readonly': readonly },
    ]"
  >
    <label
      :for="name"
      v-if="label"
      :class="['input__label', { 'sr-only': labelHidden }]"
    >
      {{ label }}
      <p v-if="info" class="input__info">{{ info }}</p>
    </label>
    <div class="input__content">
      <span v-if="prefix" class="input__prefix" v-html="prefix" />
      <textarea
        v-if="type === 'textarea'"
        class="input__input"
        :id="name"
        :aria-labelledby="name"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :value="value"
        :rows="rows"
        :maxlength="maxlength"
        :readonly="readonly"
        :aria-invalid="error"
        :aria-describedby="`error-${name}`"
        @input="updateInput"
        @change="changeInput"
      />
      <input
        v-else
        class="input__input"
        :id="name"
        :aria-labelledby="name"
        :type="type"
        :name="name"
        :autocomplete="autocomplete"
        :placeholder="placeholder"
        :value="value"
        :maxlength="maxlength"
        :tabindex="isHoneyPot ? -1 : 0"
        :readonly="readonly"
        :aria-invalid="error"
        :aria-describedby="`error-${name}`"
        @input="updateInput"
        @change="changeInput"
      />
      <span v-if="$slots.suffix" class="input__suffix">
        <slot name="suffix" />
      </span>
      <p v-if="!isNaN(count) && maxlength" class="input__count">
        {{ count }}/{{ maxlength }}
      </p>
    </div>
    <p v-if="error" :id="`error-${name}`" class="input__error">{{ error }}</p>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  inject: ['$validator'],
  props: {
    type: {
      type: String,
      default: 'text',
    },
    size: {
      validator: function (value) {
        return ['small'].includes(value);
      },
    },
    label: String,
    labelHidden: Boolean,
    placeholder: String,
    info: String,
    name: {
      type: String,
      required: true,
    },
    autocomplete: String,
    value: String,
    error: String,
    prefix: String,
    rows: {
      type: String,
      default: '5',
    },
    maxlength: [Number, String],
    count: Number,
    readonly: Boolean,
    isHoneyPot: Boolean,
  },
  methods: {
    updateInput(e) {
      this.$emit('input', e.target.value);
    },
    changeInput(e) {
      this.$emit('change', e.target.value);
    },
  },
};
</script>

<style lang="scss">
.input {
  &.is-honey-pot {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    width: 0;
    z-index: -1;
  }
}

.input__label {
  display: block;
  font-size: $font-size-small;
  margin-bottom: rem(8px);

  .input--small & {
    margin-bottom: rem(4px);
  }
}

.input__content {
  position: relative;
}

.input__prefix {
  position: absolute;
  left: rem(16px);
  top: 50%;
  transform: translateY(-50%);
  font-size: rem(24px);
  padding-right: rem(16px);

  &::after {
    content: '';
    position: absolute;
    right: 0;
    top: rem(4px);
    bottom: rem(4px);
    width: rem(1px);
    background: $color-grey-border;
  }
}

.input__suffix {
  position: absolute;
  right: rem(16px);
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  font-size: rem(24px);
  padding-left: rem(16px);

  svg {
    height: auto;
    width: auto;
  }
}

.input__input {
  display: block;
  border-radius: $border-radius-medium;
  border: rem(2px) solid $color-grey-border;
  font-size: $font-size-base-rem;
  font-weight: $font-weight-bold;
  padding: rem(10px);
  width: 100%;

  &:focus {
    border-color: $color-primary;
    outline: none;
  }

  .input--small & {
    padding: rem(8px);
  }

  .input.has-error & {
    border-color: $color-error;
  }

  .input.has-prefix & {
    padding-left: rem(72px);
  }

  .input.has-suffix & {
    padding-right: rem(72px);
  }

  .input.is-readonly & {
    background: $color-grey-border;

    &:focus {
      border-color: $color-grey-border;
    }
  }
}

.input__input::placeholder {
  color: $color-grey-darker;
  font-weight: $font-weight-thin;
  font-size: $font-size-base-rem;
}

.input__info {
  color: $color-grey-dark;
  font-size: $font-size-mini;
  margin-top: rem(4px);
}

.input__error {
  color: $color-error;
  font-size: $font-size-small;
  margin-top: rem(8px);
}

.input__count {
  position: absolute;
  right: rem(8px);
  bottom: rem(4px);
  color: $color-grey-dark;
  font-size: $font-size-extra-small;
}
</style>
