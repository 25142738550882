<template>
  <Container size="full" class="p-0">
    <b-row align-v="center" no-gutters class="row-eq-height">
      <b-col class="section-1__image-col" cols="auto" md="6">
        <figure>
          <img
            alt="How it works"
            loading="lazy"
            src="@/assets/images/klockabinnemang_bild.jpeg"
          />
        </figure>
        <Separator class="d-md-none" size="medium" />
      </b-col>
      <b-col class="text-center text-md-left" md="6">
        <Margins>
          <h2 class="mx-5">
            {{ $t('MARKETING.SECTION_1.TITLE') }}
          </h2>
          <p class="section-1__text text-transform-none text-small mx-5">
            <span>{{ $t('MARKETING.SECTION_1.DESCRIPTION.TITLE') }}</span>
            {{ $t('MARKETING.SECTION_1.DESCRIPTION.TEXT') }}
          </p>
          <p class="section-1__text text-transform-none text-small mx-5">
            <span>{{ $t('MARKETING.SECTION_1.STEPS.STEP_1.TITLE') }}</span>
            <br />
            {{ $t('MARKETING.SECTION_1.STEPS.STEP_1.TEXT') }}
          </p>
          <p class="section-1__text text-transform-none text-small mx-5">
            <span>{{ $t('MARKETING.SECTION_1.STEPS.STEP_2.TITLE') }}</span>
            <br />
            {{ $t('MARKETING.SECTION_1.STEPS.STEP_2.TEXT') }}
          </p>
          <p class="section-1__text text-transform-none text-small mx-5 mb-5">
            <span>{{ $t('MARKETING.SECTION_1.STEPS.STEP_3.TITLE') }}</span>
            <br />
            {{ $t('MARKETING.SECTION_1.STEPS.STEP_3.TEXT') }}
          </p>
        </Margins>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Separator, Margins } from '@/components';

export default {
  name: 'MarketingSectionOne',
  components: {
    Container,
    Separator,
    Margins,
  },
};
</script>
<style lang="scss" scoped>
.section-1__text span {
  font-family: $font-family-header;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
</style>
