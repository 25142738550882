<template>
  <section class="hero">
    <Container>
      <b-row>
        <b-col sm="12" md="12" lg="12" xl="10">
          <Margins>
            <h1 class="hero__title">
              <span v-html="title" />
            </h1>

            <p class="margins__double hero__carousel-header text-thin">
              {{ description }}
            </p>
          </Margins>
        </b-col>
        <b-col cols="12">
          <HeroCarousel />
        </b-col>
      </b-row>
    </Container>
  </section>
</template>

<script>
import { Container, Margins } from '@/components';
import HeroCarousel from './HeroCarousel.vue';
import { constants } from '@/mixins';

export default {
  name: 'Hero',
  mixins: [constants],
  props: {
    title: String,
    description: String,
  },
  components: {
    Container,
    HeroCarousel,
    Margins,
  },
};
</script>

<style lang="scss">
.hero {
  background-color: $color-grey;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url(../../assets/images/hero-header-watches.jpg);
  background-size: cover;
  color: $color-white;
  padding: 10vh 0 2vh;
  margin-top: -2rem;

  @include min-width(sm) {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.hero--high {
  height: rem(600px);
}

.hero__title-line {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 40px;
    height: 6px;
    background: $color-white;
  }
}

.hero__carousel-header {
  font-family: $font-family-header;
  font-size: $h2-size;
}

.hero__carousel-header--small {
  font-size: $h4-size;
}
</style>
