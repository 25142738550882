<template>
  <div>
    <div class="range__input--wrapper">
      <div
        ref="payment"
        class="whitespace-nowrap range__input--top absolute top-0 text-xl"
        :style="handleSliderValuePosition($refs.payment, value, $refs.range)"
      >
        <span v-if="steps && steps[value]" class="font-bold">{{
          steps[value].monthlyPayment.toLocaleString('sv-SE')
        }}</span>
        {{ unit }}/{{ monthText }}
      </div>
      <!-- eslint-disable-next-line vue-a11y/form-has-label -->
      <input
        v-model="value"
        ref="range"
        type="range"
        class="range__input py-4 focus:outline-none bg-transparent focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-black"
        :disabled="disabled"
        :class="computedClassList"
        :id="name"
        :name="name"
        :min="0"
        :step="1"
        :max="steps && steps.length - 1"
        :aria-invalid="!!error"
        :aria-describedby="computedDescribedby"
        @input="handleChange"
      />
      <div
        ref="total"
        class="whitespace-nowrap range__input--bottom absolute"
        :style="handleSliderValuePosition($refs.total, value, $refs.range)"
      >
        <span class="text-sm">{{ totalText }}</span>
        <br />
        <span v-if="steps && steps[value]" class="font-bold text-xl">
          {{ steps[value].totalValue.toLocaleString('sv-SE') }}
        </span>
        <span class="text-xl">{{ unit }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'SliderField',
  props: {
    name: {
      type: String,
      required: true,
    },
    steps: {
      type: Array,
      required: true,
    },
    variant: {
      type: String,
      validator: (val) => ['default', 'arrow'].includes(val),
      default: 'default',
    },
    modelValue: {
      type: Object,
    },
    disabled: Boolean,
    error: String,
    unit: {
      type: String,
      default: function () {
        return this.$t('SLIDER.UNIT');
      },
    },
    monthText: {
      type: String,
      default: function () {
        return this.$t('SLIDER.MONTH');
      },
    },
    totalText: {
      type: String,
      default: function () {
        return this.$t('SLIDER.VALUE');
      },
    },
  },

  data() {
    return {
      value: 0,
      texts: {
        unit: this.$t('SLIDER.UNIT'),
      },
    };
  },

  computed: {
    computedDescribedby() {
      return this.error ? `input-error-${name}` : null;
    },
    computedClassList() {
      return [
        'range__input--arrow',
        this.error ? 'border-error' : 'border-gray-300',
        this.disabled ? 'disabled cursor-not-allowed' : 'cursor-pointer',
      ];
    },
  },

  methods: {
    handleChange(event) {
      const val = +event.target.value;
      this.$emit('update:modelValue', this.steps[val]);
      this.$emit('change', this.steps[val]);
    },
    handleSliderValuePosition(currentSlider, val, range) {
      const max = this.steps.length - 1;

      if (+val === 0) {
        return 'left: 0; text-align: left';
      } else if (+val === max) {
        return 'right: 0; text-align: right';
      }
      const multiplier = +val / max;
      const thumbSize = 54;

      const clientWidth = currentSlider.clientWidth;

      const thumbOffset = thumbSize * multiplier;
      const priceInputOffset = (thumbSize - clientWidth) / 2;
      const totalPx = (
        range.clientWidth * multiplier -
        thumbOffset +
        priceInputOffset
      ).toFixed();
      return `left: ${totalPx}px; text-align: center`;
    },
  },
};
</script>

<style lang="scss">
.range__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 100%;
  background-color: transparent;
  --svg-arrow-left-right: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAzMiAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTAgMTBMMTIgMS4zMzk3NFYxOC42NjAzTDAgMTBaIiBmaWxsPSJ3aGl0ZSIvPgo8cGF0aCBkPSJNMzIgMTBMMjAgMTguNjYwM0wyMCAxLjMzOTc0TDMyIDEwWiIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==');
}

/* Otherwise white in Chrome */
.range__input::-webkit-slider-thumb {
  -webkit-appearance: none;
}

/* Track style */

.range__input::-moz-range-track {
  height: 1.25rem;
  border-radius: 0.75rem;
  // here
  background-color: $color-primary-hover;
}

.range__input::-webkit-slider-runnable-track {
  height: 1.25rem;
  border-radius: 0.75rem;
  // here
  background-color: $color-primary-hover;
}

.range__input::-ms-track {
  width: 100%;
  background: transparent;
  border-color: transparent;
  color: transparent;
  height: 1.25rem;
  border-radius: 0.75rem;
  // here
  background-color: $color-primary-hover;
}

/* Thumb style */

.range__input::-webkit-slider-thumb {
  width: 3.35rem;
  height: 3.35rem;
  border-radius: 50%;
  background-color: transparent;
  border: 8px solid $color-primary;
  margin-top: -17px;
}

.range__input::-moz-range-thumb {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: transparent;
  border: 8px solid $color-primary;
}

.range__input::-ms-thumb {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: transparent;
  border: 8px solid $color-primary;
  margin-top: 0.25rem / 4;
}

.range__input--arrow::-webkit-slider-thumb {
  background-color: $color-primary;
  background-image: var(--svg-arrow-left-right);
  background-repeat: no-repeat;
  background-position: center;
}

.range__input--arrow::-moz-range-thumb {
  background-color: $color-primary;
  background-image: var(--svg-arrow-left-right);
  background-repeat: no-repeat;
  background-position: center;
}

.range__input--arrow::-ms-thumb {
  background-color: $color-primary;
  background-image: var(--svg-arrow-left-right);
  background-repeat: no-repeat;
  background-position: center;
}

// Text styles & other
.range__input--wrapper {
  position: relative;
  padding-top: 1.5rem;
  padding-bottom: 2.8rem;
  margin-top: 1.8rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.absolute {
  position: absolute;
}

.range__input--top {
  top: 0;
}

.range__input--bottom {
  bottom: 0;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-family: $font-family-header;
}
</style>
