var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'input',
    { 'has-error': _vm.error },
    { 'has-prefix': _vm.prefix },
    { 'has-suffix': !!_vm.$slots.suffix },
    ( _obj = {}, _obj[("input--" + _vm.size)] = !!_vm.size, _obj ),
    { 'is-honey-pot': _vm.isHoneyPot },
    { 'is-readonly': _vm.readonly } ]},[(_vm.label)?_c('label',{class:['input__label', { 'sr-only': _vm.labelHidden }],attrs:{"for":_vm.name}},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.info)?_c('p',{staticClass:"input__info"},[_vm._v(_vm._s(_vm.info))]):_vm._e()]):_vm._e(),_c('div',{staticClass:"input__content"},[(_vm.prefix)?_c('span',{staticClass:"input__prefix",domProps:{"innerHTML":_vm._s(_vm.prefix)}}):_vm._e(),(_vm.type === 'textarea')?_c('textarea',{staticClass:"input__input",attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"rows":_vm.rows,"maxlength":_vm.maxlength,"readonly":_vm.readonly,"aria-invalid":_vm.error,"aria-describedby":("error-" + _vm.name)},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}}):_c('input',{staticClass:"input__input",attrs:{"id":_vm.name,"aria-labelledby":_vm.name,"type":_vm.type,"name":_vm.name,"autocomplete":_vm.autocomplete,"placeholder":_vm.placeholder,"maxlength":_vm.maxlength,"tabindex":_vm.isHoneyPot ? -1 : 0,"readonly":_vm.readonly,"aria-invalid":_vm.error,"aria-describedby":("error-" + _vm.name)},domProps:{"value":_vm.value},on:{"input":_vm.updateInput,"change":_vm.changeInput}}),(_vm.$slots.suffix)?_c('span',{staticClass:"input__suffix"},[_vm._t("suffix")],2):_vm._e(),(!isNaN(_vm.count) && _vm.maxlength)?_c('p',{staticClass:"input__count"},[_vm._v(" "+_vm._s(_vm.count)+"/"+_vm._s(_vm.maxlength)+" ")]):_vm._e()]),(_vm.error)?_c('p',{staticClass:"input__error",attrs:{"id":("error-" + _vm.name)}},[_vm._v(_vm._s(_vm.error))]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }