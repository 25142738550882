<template>
  <b-modal
    :ref="modalRef"
    title="Hand over modal"
    size="lg"
    hide-footer
    hide-header
  >
    <button type="button" class="modal__close" @click="hideModal">
      <CloseSVG class="icon" />
    </button>
    <Margins>
      <div class="align-items-center">
        <h4>{{ $t('HAND_OVER') }}</h4>
      </div>

      <div class="margins__double">
        <b-row>
          <b-col>
            <InputField
              :label="$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER')"
              name="referenceNumber"
              v-model.trim="formData.referenceNumber"
              :readonly="true"
              class="margins__mobile-md-double"
            />
          </b-col>
          <b-col>
            <InputField
              :label="`${$t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT')}
							(${$t('CURRENCY')})`"
              name="totalAmount"
              v-model.trim="totalAmountVsItemPrices"
              :error="
                isItemPriceTotalValid
                  ? null
                  : 'Total item price exceeds bundle price'
              "
              :readonly="true"
              class="margins__mobile-md-double"
            />
          </b-col>
        </b-row>
      </div>

      <div v-if="renewedProducts.length" class="align-items-center">
        <h4>
          {{ $t('RENEWAL') }}
        </h4>
      </div>
      <div v-if="renewedProducts.length" class="margins__double">
        <BackOfficeRenewedBundleOrdersProducts
          :products="renewedProducts"
          :is-redactable="true"
          @remove-item="moveRenewedProductToReturnedProducts"
        />
      </div>

      <div class="margins__double">
        <h6 class="products-card_header">
          {{ $t('BACK_OFFICE_ORDERS.ADD_ITEMS') }}
        </h6>
        <b-row
          v-for="(item, index) in formData.items"
          :key="index"
          align-h="center"
          no-gutters
          class="align-items-stretch justify-content-center form-row py-3"
        >
          <b-col class="px-2">
            <InputField
              size="small"
              :label="index === 0 ? $t('BACK_OFFICE_ORDERS.ITEM_NAME') : ''"
              :name="`name-${index}`"
              v-model.trim="item.name"
              :error="errors.first(`name-${index}`)"
              v-validate="{ max: 100 }"
              class="margins__mobile-md-double form-control"
              :data-vv-as="$t('BACK_OFFICE_ORDERS.ITEM_NAME')"
            />
          </b-col>
          <b-col class="px-2">
            <InputField
              size="small"
              :label="index === 0 ? $t('BACK_OFFICE_ORDERS.ITEM_SERIAL') : ''"
              :name="`serial-${index}`"
              v-model.trim="item.serial"
              :error="errors.first(`serial-${index}`)"
              v-validate="{ max: 100 }"
              class="margins__mobile-md-double form-control"
              :data-vv-as="$t('BACK_OFFICE_ORDERS.ITEM_SERIAL')"
            />
          </b-col>
          <b-col class="px-2">
            <InputField
              size="small"
              :label="index === 0 ? $t('BACK_OFFICE_ORDERS.ITEM_CODE') : ''"
              :name="`code-${index}`"
              v-model.trim="item.code"
              :error="errors.first(`code-${index}`)"
              v-validate="{ required: true, max: 100 }"
              class="margins__mobile-md-double form-control"
              :data-vv-as="$t('BACK_OFFICE_ORDERS.ITEM_CODE')"
            />
          </b-col>
          <b-col class="px-2">
            <InputField
              type="number"
              size="small"
              :label="index === 0 ? $t('BACK_OFFICE_ORDERS.ITEM_PRICE') : ''"
              :name="`price-${index}`"
              v-model.trim="item.price"
              :error="errors.first(`price-${index}`)"
              v-validate="{ required: true }"
              class="margins__mobile-md-double form-control"
              :data-vv-as="$t('BACK_OFFICE_ORDERS.ITEM_PRICE')"
            />
          </b-col>
          <b-col cols="1" class="align-self-center px-2">
            <div class="pt-4 button-group">
              <Button
                variant="text"
                @click="addItem()"
                :title="$t('CLOSE_DETAILS')"
                :disabled="formData.items.length > 3"
              >
                <PlusSVG class="icon" />
              </Button>
              <Button
                v-if="formData.items.length > 1"
                variant="text"
                @click="removeItem(index)"
                :title="$t('CLOSE_DETAILS')"
              >
                <CloseSVG class="icon" />
              </Button>
            </div>
          </b-col>
        </b-row>
      </div>
      <div v-if="returnedProducts.length" class="align-items-center">
        <h4>{{ $t('BACK_OFFICE_ORDERS.RETURNED') }}</h4>
      </div>
      <div v-if="returnedProducts.length" class="margins__double">
        <BackOfficeRenewedBundleOrdersProducts
          :products="returnedProducts"
          :is-redactable="true"
          @remove-item="moveReturnedProductToRenewedProducts"
        />
      </div>

      <div class="text-center margins__double">
        <Button type="submit" @click="handleHandOver()" :title="$t('HAND_OVER')"
          >{{ $t('HAND_OVER') }}
        </Button>
      </div>
    </Margins>
  </b-modal>
</template>

<script>
import { mapActions } from 'vuex';
import {
  SET_ORDER_HANDED_OVER,
  GET_BUNDLE_ORDER_PRODUCTS,
  GET_RETURNED_ORDER_PRODUCTS,
} from '@/types';
import { Button, Margins, InputField } from '@/components';
import { constants } from '@/mixins';
import CloseSVG from '@/assets/images/times-circle-regular.svg';
import PlusSVG from '@/assets/images/plus-circle-solid.svg';
import BackOfficeRenewedBundleOrdersProducts from './BackOfficeRenewedBundleOrdersProducts.vue';

export default {
  name: 'ApplicationHandOverModal',
  mixins: [constants],
  components: {
    Margins,
    CloseSVG,
    InputField,
    Button,
    PlusSVG,
    BackOfficeRenewedBundleOrdersProducts,
  },
  data() {
    return {
      totalAmount: null,
      isRenewal: false,
      renewedProducts: [],
      returnedProducts: [],
      formData: this.initFormData(),
    };
  },
  methods: {
    ...mapActions([
      SET_ORDER_HANDED_OVER,
      GET_BUNDLE_ORDER_PRODUCTS,
      GET_RETURNED_ORDER_PRODUCTS,
    ]),
    async handleHandOver() {
      const valid = await this.$validator.validateAll();
      if (!valid) {
        return false;
      }

      if (this.isRenewal) {
        this.formData.items = [...this.formData.items, ...this.renewedProducts];
      }

      await this.SET_ORDER_HANDED_OVER(this.formData);
      this.$emit('handed-over', this.formData.referenceNumber);
      this.hideModal();
      this.formData = this.initFormData();
    },
    initFormData() {
      return {
        referenceNumber: null,
        items: [this.newItem()],
      };
    },
    newItem() {
      return {
        name: null,
        serial: null,
        code: null,
        price: null,
      };
    },
    async showModal(order) {
      this.totalAmount = order.totalAmount;
      this.isRenewal = order.renewedFrom !== '';
      this.renewedProducts =
        this.isRenewal &&
        (await this.GET_BUNDLE_ORDER_PRODUCTS(order.referenceNumber));
      this.returnedProducts =
        this.isRenewal &&
        (await this.GET_RETURNED_ORDER_PRODUCTS(order.referenceNumber));
      this.formData.referenceNumber = order.referenceNumber;
      this.$refs[this.modalRef].show();
    },
    hideModal() {
      this.$refs[this.modalRef].hide();
    },
    addItem() {
      this.formData.items.push(this.newItem());
    },
    removeItem(itemIndex) {
      this.formData.items = this.formData.items.filter(
        (item, index) => index !== itemIndex
      );
    },
    moveRenewedProductToReturnedProducts(id) {
      const returnedProduct = this.renewedProducts.find(
        (returnedProduct) => returnedProduct.id === id
      );

      this.renewedProducts = this.renewedProducts.filter(
        (renewedProduct) => renewedProduct.id !== id
      );

      this.returnedProducts = [...this.returnedProducts, returnedProduct];
    },
    moveReturnedProductToRenewedProducts(id) {
      const renewedProduct = this.returnedProducts.find(
        (renewedProduct) => renewedProduct.id === id
      );

      this.returnedProducts = this.returnedProducts.filter(
        (returnedProduct) => returnedProduct.id !== id
      );

      this.renewedProducts = [...this.renewedProducts, renewedProduct];
    },
  },
  computed: {
    modalRef() {
      return `hand-over-modal`;
    },
    totalAmountVsItemPrices() {
      return `${this.totalAmount} / ${this.itemPriceTotal}`;
    },
    itemPriceTotal() {
      return this.formData.items.reduce(
        (total, { price }) => total + +price,
        0
      );
    },
    isItemPriceTotalValid() {
      return this.itemPriceTotal <= this.totalAmount;
    },
  },
};
</script>

<style lang="scss" scoped>
.button-group button {
  padding: 0 rem(8px) 0 0;
  + button {
    padding: 0;
  }
}
</style>
