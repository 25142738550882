<template>
  <div
    :class="[
      'card',
      {
        [`card--padding-${padding}`]: !!padding,
        [`card--color-${color}`]: !!color,
        [`card--variant-${variant}`]: !!variant,
        [`card--text-${text}`]: !!text,
        [`card--overflow-${overflow}`]: !!overflow,
      },
    ]"
  >
    <div class="card__content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    padding: {
      validator: function (value) {
        return ['small', 'medium', 'large'].includes(value);
      },
    },
    color: {
      validator: function (value) {
        return ['grey', 'primary', 'black', 'transparent'].includes(value);
      },
    },
    variant: {
      validator: function (value) {
        return ['border', 'corner-small', 'corner'].includes(value);
      },
    },
    text: {
      validator: function (value) {
        return ['white'].includes(value);
      },
    },
    overflow: {
      validator: function (value) {
        return ['hidden'].includes(value);
      },
    },
  },
};
</script>

<style lang="scss">
.card {
  background: $color-white;
  border-radius: $border-radius;
}

.card--padding-small {
  padding: 1rem;
}

.card--padding-medium {
  padding: 1rem;

  @include min-width(md) {
    padding: 2rem;
  }
}

.card--padding-large {
  padding: 1rem;

  @include min-width(md) {
    padding: 3rem;
  }
}

.card--color-grey {
  background: $color-grey-light;
}

.card--color-black {
  background: $color-black;
}

.card--color-primary {
  background: $color-primary;
  color: $color-white;
}

.card--color-transparent {
  background: transparent;
}

.card--variant-border {
  border-color: $color-grey-border;
}

.card--variant-corner-small {
  border-radius: $border-radius-medium;

  .apply-header-picture {
    border-radius: $border-radius-medium $border-radius-medium 0 0;
  }
}

.card--variant-corner {
  border-radius: 2rem;
}

.card--text-white {
  color: $color-white;
}

.card--overflow-hidden {
  overflow: hidden;
}

.card__image {
  .card--padding-small & {
    margin: -1rem -1rem 0;
  }

  .card--padding-medium & {
    margin: -1rem -1rem 0;

    @include min-width(md) {
      margin: -2rem -2rem 0;
    }
  }

  .card--padding-large & {
    margin: -1rem -1rem 0;

    @include min-width(md) {
      margin: -3rem -3rem 0;
    }
  }
}
</style>
