<template>
  <Container>
    <b-row align-v="center">
      <b-col cols="auto" class="marketing-card__arrow d-md-none" @click="prev">
        <ArrowLeftSVG />
      </b-col>
      <b-col
        cols="4"
        v-for="(card, index) in $t('TESTIMONIALS')"
        :key="index"
        class="d-none d-md-block h-full"
      >
        <Card padding="medium" class="marketing-card">
          <figure v-if="card.IMAGE" class="marketing-card__figure mb-4">
            <img
              class="marketing-card__image"
              :src="require('@/assets/images/' + card.IMAGE)"
              loading="lazy"
              alt="Stjärnurmakarna"
            />
          </figure>
          <h5 class="marketing-card__text mb-2 text-small">
            “{{ card.TEXT }}”
          </h5>
          <p class="marketing-card__signature text-extra-small">
            - {{ card.SIGNATURE }} -
          </p>
        </Card>
      </b-col>
      <b-col class="d-md-none">
        <b-carousel ref="marketingCarousel" :interval="6000">
          <b-carousel-slide
            v-for="(card, index) in $t('TESTIMONIALS')"
            :key="index"
            class="p-0"
          >
            <Margins>
              <Card padding="medium" class="marketing-card">
                <figure class="marketing-card__figure mb-4">
                  <img
                    class="marketing-card__image"
                    :src="require('@/assets/images/' + card.IMAGE)"
                    loading="lazy"
                    alt="Stjärnurmakarna"
                  />
                </figure>
                <h5
                  class="marketing-card__text mb-2 text-small text-black text-center"
                >
                  “{{ card.TEXT }}”
                </h5>
                <p
                  class="marketing-card__signature text-extra-small text-black text-center"
                >
                  - {{ card.SIGNATURE }} -
                </p>
              </Card>
            </Margins>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
      <b-col cols="auto" class="marketing-card__arrow d-md-none" @click="next">
        <ArrowRightSVG />
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Margins, Card } from '@/components';
import ArrowLeftSVG from '@/assets/images/arrow-left.svg';
import ArrowRightSVG from '@/assets/images/arrow-right.svg';

export default {
  name: 'MarketingSectionSix',
  components: {
    Container,
    Margins,
    Card,
    ArrowLeftSVG,
    ArrowRightSVG,
  },
  methods: {
    prev() {
      this.$refs.marketingCarousel.prev();
    },
    next() {
      this.$refs.marketingCarousel.next();
    },
  },
};
</script>
<style lang="scss">
.marketing-card {
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.08);
}

.marketing-card__image {
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
}

.marketing-card__text {
  line-height: 1.3rem;
  min-height: 7rem;
}

.marketing-card__signature {
  font-style: italic;
}

.marketing-card__arrow {
  padding: 2rem;
}
</style>
