<template>
  <div class="loan-application-login">
    <Separator size="large" />
    <Margins class="text-center">
      <Button
        :href="`${window.location.origin}/oauth2/authorization/signicat`"
        >{{ $t('LOGIN_BANK_ID') }}</Button
      >
      <div class="text-small text-grey margins__triple">
        <a href="https://www.bankid.com/" target="_blank">{{
          $t('LOGIN_INFO')
        }}</a>
        <p>{{ $t('LOGIN_INFO_2') }}</p>
      </div>
    </Margins>
    <BankIdSVG class="bank-id-svg" />
  </div>
</template>

<script>
import { Margins, Button, Separator } from '@/components';
import { constants } from '@/mixins';
import BankIdSVG from '@/assets/images/bank-id-icon.svg';

export default {
  name: 'LoanApplicationLogin',
  mixins: [constants],
  components: {
    Margins,
    Button,
    Separator,
    BankIdSVG,
  },
};
</script>

<style lang="scss">
.loan-application-login {
  position: relative;
  z-index: 1;
}

.bank-id-svg {
  position: absolute;
  fill: $color-grey-border;
  width: 55%;
  top: -90%;
  z-index: -1;
  right: -15%;
  transform: rotate(-30deg);
}
</style>
