<template>
  <b-carousel
    id="hero-carousel"
    class="hero-carousel"
    :interval="6000"
    controls
  >
    <b-carousel-slide v-for="(item, index) in items" :key="index">
      <p class="h3 carousel-content">
        {{ item.TITLE }}
        <span class="hero-carousel__text text-thin text-transform-none">{{
          item.TEXT
        }}</span>
      </p>
    </b-carousel-slide>
  </b-carousel>
</template>

<script>
import { getDynamicTranslation } from '@/utils';

export default {
  name: 'HeroCarousel',
  computed: {
    items() {
      const latestReplaceYear = new Date().getFullYear() + 3;
      return getDynamicTranslation(this.$i18n, 'HERO_CAROUSEL', [
        latestReplaceYear,
      ]);
    },
  },
};
</script>

<style lang="scss">
.hero-carousel {
  margin-top: 2rem;
  min-height: rem(300px);

  @include min-width(sm) {
    min-height: rem(170px);
  }
}

.hero-carousel__text {
  font-family: $font-family-header;
}
</style>
