<template>
  <table class="table">
    <thead>
      <tr>
        <th class="text-left">
          {{ $t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER') }}
        </th>
        <th class="text-left px-3">
          {{ $t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT') }} ({{ $t('CURRENCY') }})
        </th>
        <th class="text-left px-3">
          {{ $t('BACK_OFFICE_ORDERS.BUNDLE_PRODUCT_CODE') }}
        </th>
        <th class="text-left px-3">
          {{ $t('BACK_OFFICE_ORDERS.CREATED') }}
        </th>
        <th class="text-left px-3">
          {{ $t('BACK_OFFICE_ORDERS.DELIVERY_STORE') }}
        </th>
        <th>{{ $t('BACK_OFFICE_ORDERS.STATUS') }}</th>
      </tr>
    </thead>
    <tbody v-if="!isRenewalBundleExist">
      <tr>
        <td colspan="8">{{ $t('ORDERS_NO_RESULTS') }}</td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td class="px-3">{{ renewedBundle.referenceNumber }}</td>
        <td class="px-3">{{ renewedBundle.totalAmount }}</td>
        <td class="px-3">{{ renewedBundle.bundleProductCode }}</td>
        <td class="px-3">
          {{
            new Intl.DateTimeFormat($i18n.locale, {
              dateStyle: 'short',
              timeStyle: 'short',
            }).format(new Date(renewedBundle.createdDtime))
          }}
        </td>
        <td class="px-3">{{ renewedBundle.deliveryStore }}</td>
        <td class="px-3">
          <Badge :variant="statusVariant">
            {{ orderTranslation(renewedBundle.status) }}
          </Badge>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { constants } from '@/mixins';
import { Badge } from '@/components';

export default {
  name: 'BackOfficeBundleRenewalTable',
  components: { Badge },
  props: {
    renewedBundle: Object,
  },
  mixins: [constants],
  methods: {
    switchStatusVariant(status) {
      switch (status) {
        case this.ORDER_STATUSES.CANCELLED:
        case this.ORDER_STATUSES.DENIED:
          return 'error';
        case this.ORDER_STATUSES.PENDING:
        case this.ORDER_STATUSES.SUBMITTED:
        case this.ORDER_STATUSES.AWAITING_RENEWAL:
          return 'info';
        case this.ORDER_STATUSES.COMPLETED:
        case this.ORDER_STATUSES.READY_TO_RENEW:
          return 'success';
        case this.ORDER_STATUSES.HANDED_OVER:
        case this.ORDER_STATUSES.RENEWED:
          return 'done';
        default:
      }
    },
    orderTranslation(status) {
      return this.$t(`ORDER_STATUSES.${status}`);
    },
  },
  computed: {
    isRenewalBundleExist() {
      return this.renewedBundle && Object.entries(this.renewedBundle).length;
    },
    statusVariant() {
      return this.switchStatusVariant(this.renewedBundle.status);
    },
  },
};
</script>
