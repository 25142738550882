<template>
  <Container>
    <b-row align-v="center" class="justify-content-center">
      <b-col
        cols="2"
        v-for="(logo, index) in logos"
        :key="index"
        class="d-none d-md-block"
      >
        <Margins>
          <figure class="partner__figure mb-4">
            <img
              class="partner__image"
              :src="logo.image"
              loading="lazy"
              :alt="logo.alt"
            />
          </figure>
        </Margins>
      </b-col>
      <b-col cols="auto" class="partner__arrow d-md-none" @click="prev">
        <ArrowLeftSVG />
      </b-col>
      <b-col class="d-md-none">
        <b-carousel ref="marketingCarousel" :interval="6000">
          <b-carousel-slide
            v-for="(logo, index) in logos"
            :key="index"
            class="p-0"
          >
            <figure class="partner__figure mb-4">
              <img
                class="partner__image"
                :src="logo.image"
                loading="lazy"
                :alt="logo.alt"
              />
            </figure>
          </b-carousel-slide>
        </b-carousel>
      </b-col>
      <b-col cols="auto" class="partner__arrow d-md-none" @click="next">
        <ArrowRightSVG />
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Margins } from '@/components';
import ArrowLeftSVG from '@/assets/images/arrow-left.svg';
import ArrowRightSVG from '@/assets/images/arrow-right.svg';

import Casio from '@/assets/images/partner-logos/Casio.jpg';
import Certina from '@/assets/images/partner-logos/CERTINA.jpg';
import Gant from '@/assets/images/partner-logos/GANT.jpg';
import Garmin from '@/assets/images/partner-logos/Garmin.jpg';
import Longines from '@/assets/images/partner-logos/Longines.jpg';
import Mockberg from '@/assets/images/partner-logos/Mockberg.jpg';
import Morris from '@/assets/images/partner-logos/Morris.jpg';
import Oris from '@/assets/images/partner-logos/ORIS.jpg';
import Seiko from '@/assets/images/partner-logos/Seiko.jpg';
import Swiss from '@/assets/images/partner-logos/Swiss.jpg';
import TagHeuer from '@/assets/images/partner-logos/TAG-Heuer.jpg';
import Tissot from '@/assets/images/partner-logos/Tissot.jpg';
import Victorinox from '@/assets/images/partner-logos/Victorinox.jpg';

export default {
  name: 'MarketingSectionFive',
  components: {
    Container,
    Margins,
    ArrowLeftSVG,
    ArrowRightSVG,
  },
  data() {
    return {
      logos: [
        {
          alt: 'Casio',
          image: Casio,
        },
        {
          alt: 'Cartina',
          image: Certina,
        },
        {
          alt: 'GANT',
          image: Gant,
        },
        {
          alt: 'Longines',
          image: Longines,
        },
        {
          alt: 'Garmin',
          image: Garmin,
        },
        {
          alt: 'Mockberg',
          image: Mockberg,
        },
        {
          alt: 'Seiko',
          image: Seiko,
        },
        {
          alt: 'Morris',
          image: Morris,
        },
        {
          alt: 'ORIS',
          image: Oris,
        },
        {
          alt: 'Swiss',
          image: Swiss,
        },
        {
          alt: 'Victorinox',
          image: Victorinox,
        },
        {
          alt: 'TAG Heuer',
          image: TagHeuer,
        },
        {
          alt: 'Tissot',
          image: Tissot,
        },
      ],
    };
  },
  methods: {
    prev() {
      this.$refs.marketingCarousel.prev();
    },
    next() {
      this.$refs.marketingCarousel.next();
    },
  },
};
</script>
<style lang="scss">
.partner__image {
  margin-left: auto;
  margin-right: auto;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  opacity: 0.5;
}

.partner__arrow {
  padding: 2rem;
}
</style>
