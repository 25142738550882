<template>
  <Container>
    <b-row align-v="center">
      <b-col class="text-center">
        <h1 class="text-transform-none marketing-call-to-action__title">
          {{ $t('MARKETING.CALL_TO_ACTION.START') }}
          <span>{{ $t('MARKETING.CALL_TO_ACTION.PRICE_FROM') }}</span> 149
          <span>{{ $t('MARKETING.CALL_TO_ACTION.UNIT') }}</span>
        </h1>
        <Button class="mt-4" :to="{ name: ROUTES.SUBSCRIPTION.name }">
          {{ $t('MARKETING.CALL_TO_ACTION.BUTTON') }}
        </Button>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Button } from '@/components';
import { constants } from '@/mixins';

export default {
  name: 'MarketingSectionFour',
  mixins: [constants],
  components: {
    Container,
    Button,
  },
};
</script>

<style lang="scss">
.marketing-call-to-action__title span {
  font-family: $font-family;
  font-weight: $font-weight;
}
</style>
