import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

import Home from './views/Home.vue';
import store from './store';
import {
  SET_LANGUAGE,
  HAS_ERROR,
  NOTIFICATION,
  GET_IS_PHYSICAL_STORE,
} from './types';
import { STEPS, ROUTES, DECISION_TYPES } from './constants';
import i18n from './i18n';
import {
  setDocumentTitle,
  setHtmlElementLanguage,
  kebabToSnakeCaseForTranslation,
} from './utils';

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      }, 250);
    });
  },
  routes: [
    {
      path: ROUTES.HOME.path,
      name: ROUTES.HOME.name,
      component: Home,
    },
    {
      path: ROUTES.APPLY.path,
      name: ROUTES.APPLY.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.APPLY.name),
      },
      component: () =>
        // route level code-splitting
        // this generates a separate chunk (apply.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        import(/* webpackChunkName: "apply" */ './views/Apply.vue'),
    },
    {
      path: ROUTES.LOGIN_SUCCESS.path,
      name: ROUTES.LOGIN_SUCCESS.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOGIN_SUCCESS.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "login-success" */ './views/Login/Success.vue'
        ),
    },
    {
      path: ROUTES.LOGIN_FAIL.path,
      name: ROUTES.LOGIN_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOGIN_FAIL.name),
      },
      component: () =>
        import(/* webpackChunkName: "login-fail" */ './views/Login/Fail.vue'),
    },
    {
      path: ROUTES.SELECT_STORE.path,
      name: ROUTES.SELECT_STORE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.SELECT_STORE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "select-store" */ './views/SelectStore/SelectStore.vue'
        ),
    },
    {
      path: ROUTES.FAQ.path,
      name: ROUTES.FAQ.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.FAQ.name),
      },
      component: () => import(/* webpackChunkName: "faq" */ './views/Faq.vue'),
    },
    {
      path: ROUTES.RENEWAL.path,
      name: ROUTES.RENEWAL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.RENEWAL.name),
      },
      props: (route) => ({
        reference: route.params.reference,
      }),
      component: () =>
        import(
          /* webpackChunkName: "renewal" */ './views/LoanApplication/RenewalApplicationLoading.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION.path,
      name: ROUTES.LOAN_APPLICATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.LOAN_APPLICATION.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application" */ './views/LoanApplication/LoanApplication.vue'
        ),
    },
    {
      path: ROUTES.LOAN_APPLICATION_LOADING.path,
      name: ROUTES.LOAN_APPLICATION_LOADING.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.LOAN_APPLICATION_LOADING.name
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "loan-application-loading" */ './views/LoanApplication/LoanApplicationLoading.vue'
        ),
    },
    {
      path: ROUTES.DECISION_MANUAL_INSPECTION.path,
      name: ROUTES.DECISION_MANUAL_INSPECTION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.DECISION_MANUAL_INSPECTION.name
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-manual-inspection" */ './views/Decisions/ManualInspection.vue'
        ),
    },
    {
      path: ROUTES.DECISION_DENIED.path,
      name: ROUTES.DECISION_DENIED.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_DENIED.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-denied" */ './views/Decisions/Denied.vue'
        ),
    },
    {
      path: ROUTES.DECISION_SIGN.path,
      name: ROUTES.DECISION_SIGN.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.DECISION_SIGN.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "decision-sign" */ './views/Decisions/Sign.vue'
        ),
    },
    {
      path: ROUTES.BANK_ID_SUCCESS.path,
      name: ROUTES.BANK_ID_SUCCESS.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BANK_ID_SUCCESS.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "bank-id-success" */ './views/BankId/Success.vue'
        ),
    },
    {
      path: ROUTES.BANK_ID_FAIL.path,
      name: ROUTES.BANK_ID_FAIL.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BANK_ID_FAIL.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "bank-id-fail" */ './views/BankId/Fail.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE.path,
      name: ROUTES.BACK_OFFICE.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BACK_OFFICE.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office" */ './views/BackOffice/Home.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_LOGIN.path,
      name: ROUTES.BACK_OFFICE_LOGIN.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.BACK_OFFICE_LOGIN.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-login" */ './views/BackOffice/Login.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_REGISTRATION.path,
      name: ROUTES.BACK_OFFICE_REGISTRATION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_REGISTRATION.name
        ),
      },
      props: (route) => ({
        invitationId: route.params.invitationId,
      }),
      component: () =>
        import(
          /* webpackChunkName: "back-office-registration" */ './views/BackOffice/Registration.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_PASSWORD_RESET.path,
      name: ROUTES.BACK_OFFICE_PASSWORD_RESET.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_PASSWORD_RESET.name
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-password-reset" */ './views/BackOffice/PasswordReset.vue'
        ),
    },
    {
      path: ROUTES.BACK_OFFICE_PASSWORD_LOST.path,
      name: ROUTES.BACK_OFFICE_PASSWORD_LOST.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(
          ROUTES.BACK_OFFICE_PASSWORD_LOST.name
        ),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office-password-lost" */ './views/BackOffice/PasswordLost.vue'
        ),
    },
    {
      path: ROUTES.SUBSCRIPTION.path,
      name: ROUTES.SUBSCRIPTION.name,
      meta: {
        titleKey: kebabToSnakeCaseForTranslation(ROUTES.SUBSCRIPTION.name),
      },
      component: () =>
        import(
          /* webpackChunkName: "back-office" */ './views/Subscription.vue'
        ),
    },
    {
      path: '*',
      redirect: { name: ROUTES.HOME.name },
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  const {
    HOME,
    APPLY,
    LOAN_APPLICATION,
    LOAN_APPLICATION_LOADING,
    DECISION_DENIED,
    DECISION_MANUAL_INSPECTION,
    DECISION_SIGN,
    BACK_OFFICE,
    BACK_OFFICE_LOGIN,
    BACK_OFFICE_REGISTRATION,
    BACK_OFFICE_PASSWORD_LOST,
    BACK_OFFICE_PASSWORD_RESET,
  } = ROUTES;
  const {
    state: {
      currentStep,
      loanApplicationData,
      loanApplicationDecision,
      hasError,
      backOfficeLoggedIn,
    },
    commit,
    dispatch,
  } = store;

  let goto;

  const isPhysicalStore = await dispatch(GET_IS_PHYSICAL_STORE);
  const publicStoreRoutes = [
    BACK_OFFICE_LOGIN.name,
    BACK_OFFICE_REGISTRATION.name,
    BACK_OFFICE_PASSWORD_LOST.name,
    BACK_OFFICE_PASSWORD_RESET.name,
  ];

  if (
    !publicStoreRoutes.includes(to.name) &&
    isPhysicalStore &&
    !backOfficeLoggedIn
  ) {
    next({ name: BACK_OFFICE_LOGIN.name });
    return;
  }

  switch (to.name) {
    case APPLY.name:
    case LOAN_APPLICATION.name:
      if (currentStep < STEPS.apply && !localStorage.getItem('isRenewable')) {
        goto = { name: HOME.name };
      }
      break;
    case LOAN_APPLICATION_LOADING.name:
      if (
        (currentStep < STEPS.apply && !localStorage.getItem('isRenewable')) ||
        !loanApplicationData
      ) {
        goto = { name: HOME.name };
      }
      break;
    case DECISION_DENIED.name:
      if (
        currentStep < STEPS.decision ||
        ![DECISION_TYPES.DENIED].includes(loanApplicationDecision.decision)
      ) {
        goto = { name: HOME.name };
      }
      break;
    case DECISION_MANUAL_INSPECTION.name:
      if (
        currentStep < STEPS.decision ||
        ![
          DECISION_TYPES.MANUAL_INSPECTION,
          DECISION_TYPES.SUPPLEMENTING_REQUIRED,
          DECISION_TYPES.NONE,
        ].includes(loanApplicationDecision.decision)
      ) {
        goto = { name: HOME.name };
      }
      break;
    case DECISION_SIGN.name:
      if (
        currentStep < STEPS.sign ||
        ![DECISION_TYPES.APPROVED].includes(loanApplicationDecision.decision)
      ) {
        goto = { name: HOME.name };
      }
      break;
    case BACK_OFFICE_LOGIN.name: {
      if (backOfficeLoggedIn) {
        goto = { name: BACK_OFFICE.name };
      }
      break;
    }
    default:
  }

  if (hasError) {
    commit(HAS_ERROR, false);
  }
  commit(NOTIFICATION, null);

  goto ? next(goto) : next();
});

router.afterEach((to) => {
  // On every route change set new document title
  setDocumentTitle(
    to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
    i18n.t('ROUTER.DEFAULT_TITLE')
  );

  // If url has query param ?lang=, set locale
  const languageFromQuery = to.query.lang;
  if (i18n.availableLocales.includes(languageFromQuery)) {
    i18n.locale = languageFromQuery;
    setHtmlElementLanguage(languageFromQuery);
    setDocumentTitle(
      to.meta.titleKey ? i18n.t(`ROUTER.${to.meta.titleKey}`) : null,
      i18n.t('ROUTER.DEFAULT_TITLE')
    );
    store.dispatch(SET_LANGUAGE, languageFromQuery);
  }
});

export default router;
