var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-responsive"},[_c('ApplicationHandOverModal',{ref:"hand-over-modal"}),_c('table',{staticClass:"table"},[_c('thead',[_c('tr',[_c('th'),_c('th',{staticClass:"text-left"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER'))+" ")]),_c('th',{staticClass:"text-left px-3"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT'))+" ("+_vm._s(_vm.$t('CURRENCY'))+") ")]),_c('th',{staticClass:"text-left px-3"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.PAYMENT_AMOUNT'))+" ")]),_c('th',{staticClass:"text-left px-3"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.BUNDLE_PRODUCT_CODE'))+" ")]),_c('th',{staticClass:"text-left px-3"},[_vm._v(_vm._s(_vm.$t('BACK_OFFICE_ORDERS.CREATED')))]),_c('th',{staticClass:"text-left px-3"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.DELIVERY_STORE'))+" ")]),_c('th',[_vm._v(_vm._s(_vm.$t('BACK_OFFICE_ORDERS.STATUS')))]),_c('th',{staticClass:"text-center small px-3"},[_vm._v(" "+_vm._s(_vm.$t('BACK_OFFICE_ORDERS.ACTIONS'))+" ")])])]),(_vm.filteredOrders.length === 0)?_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"8"}},[_vm._v(_vm._s(_vm.$t('ORDERS_NO_RESULTS')))])])]):_vm._l((_vm.filteredOrders),function(order){
var _obj;
return _c('tbody',{key:order.createdDtime},[_c('tr',{class:[
          {
            'has-highlight': _vm.isHighlighted(order),
            'has-background': _vm.isActive(order),
          } ]},[_c('td',[_c('Button',{attrs:{"title":_vm.$t('CLOSE_DETAILS'),"variant":"ghost"},on:{"click":function($event){return _vm.showCustomerDetails(order.referenceNumber)}}},[(_vm.displayOrderDetails[order.referenceNumber])?[_c('div',[_c('CloseDetailsSVG',{staticClass:"icon"})],1)]:[_c('div',[_c('ViewDetailsSVG',{staticClass:"icon"})],1)]],2),_c('Button',{attrs:{"title":_vm.$t('CLOSE_DETAILS'),"variant":"ghost"},on:{"click":function($event){return _vm.showProductDetails(order.referenceNumber)}}},[(_vm.displayOrderProducts[order.referenceNumber])?[_c('div',[_c('ChevronUp',{staticClass:"icon"})],1)]:[_c('div',[_c('ChevronDown',{staticClass:"icon"})],1)]],2)],1),_c('td',[_c('strong',[_vm._v(_vm._s(order.referenceNumber))])]),_c('td',[_c('strong',[_vm._v(_vm._s(order.totalAmount))])]),_c('td',{staticClass:"text-left px-3"},[_vm._v(" "+_vm._s(_vm.paymentAmount(order.totalAmount, order.financingCost))+" ")]),_c('td',[_c('strong',[_vm._v(_vm._s(order.bundleProductCode))])]),_c('td',{staticClass:"px-3"},[_vm._v(" "+_vm._s(new Intl.DateTimeFormat(_vm.$i18n.locale, { dateStyle: 'short', timeStyle: 'short', }).format(new Date(order.createdDtime)))+" ")]),_c('td',[_vm._v(" "+_vm._s(order.deliveryStore)+" ")]),_c('td',{staticClass:"text-center px-3"},[_c('Badge',{attrs:{"variant":_vm.orderStatusVariant(order.status, order.decision)}},[_vm._v(" "+_vm._s(_vm.orderTranslation(order.status, order.decision))+" ")])],1),_c('td',{class:[
            'text-center px-3',
            ( _obj = {}, _obj['no-padding'] = _vm.hasNoPadding(order), _obj ) ]},[(order.status === _vm.ORDER_STATUSES.COMPLETED)?_c('Button',{attrs:{"title":_vm.$t('HAND_OVER'),"variant":"ghost"},on:{"click":function($event){return _vm.showHandOverModal(order)}}},[_c('CheckSVG',{staticClass:"icon"})],1):_vm._e()],1)]),(_vm.displayOrderDetails[order.referenceNumber])?_c('tr',{class:[
          {
            'has-background': _vm.isHighlighted(order) || _vm.isActive(order),
          } ]},[_c('td',{attrs:{"colspan":"9"}},[_c('BackOfficeBundleOrderDetails',{attrs:{"orderDetails":_vm.bundleOrderDetails[order.referenceNumber]}})],1)]):_vm._e(),(_vm.displayOrderProducts[order.referenceNumber])?_c('tr',{staticClass:"without-border",class:[
          {
            'has-background': _vm.isHighlighted(order) || _vm.isActive(order),
          } ]},[_c('td',{attrs:{"colspan":"9"}},[_c('BackOfficeBundleOrderProducts',{attrs:{"is-renewal":_vm.isRenewal(order),"products":_vm.bundleOrderProducts[order.referenceNumber],"renewed-bundle":_vm.renewedBundle[order.referenceNumber],"returned-products":_vm.returnedProducts[order.referenceNumber]}})],1)]):_vm._e()])})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }