<template>
  <div>
    <template v-if="isRenewal">
      <div>
        <BackOfficeRenewedBundleOrdersProducts
          :products="products"
          :returned-products="returnedProducts"
          :renewed-bundle="renewedBundle"
        />
      </div>
    </template>
    <template v-else>
      <table class="table">
        <thead>
          <tr>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_NAME') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_SERIAL') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_CODE') }}</th>
            <th>{{ $t('BACK_OFFICE_ORDERS.ITEM_PRICE') }}</th>
          </tr>
        </thead>
        <tbody v-for="(product, index) in products" :key="index">
          <tr>
            <td class="text-center">
              <strong>{{ product.name }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ product.serial }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ product.code }}</strong>
            </td>
            <td class="text-center">
              <strong>{{ product.price }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
import BackOfficeRenewedBundleOrdersProducts from './BackOfficeRenewedBundleOrdersProducts.vue';
export default {
  name: 'BackOfficeBundleOrderProducts',
  components: { BackOfficeRenewedBundleOrdersProducts },
  props: {
    products: Array,
    renewedBundle: Object,
    returnedProducts: Array,
    isRenewal: Boolean,
  },
};
</script>
