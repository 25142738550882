<template>
  <div>
    <div class="menu-toggle" @click="visible = true">
      <svg viewBox="0 0 100 80" width="40" height="40">
        <rect width="90" height="7" rx="8"></rect>
        <rect y="30" width="90" height="7" rx="8"></rect>
        <rect y="60" width="90" height="7" rx="8"></rect>
      </svg>
    </div>
    <div v-if="visible" class="mobile-menu-container">
      <Card v-if="isMarketingView" color="black" text="white">
        <p class="text-center text-uppercase py-2 px-3">
          {{ $t('MARKETING.DISCLAIMER') }}
        </p>
      </Card>
      <div class="close-container">
        <span class="close" @click="visible = false" />
      </div>
      <div><slot></slot></div>
    </div>
    <div class="desktop-menu-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { Card } from '@/components';
import { constants } from '@/mixins';
export default {
  name: 'MobileMenuToggler',
  components: {
    Card,
  },
  props: {},
  data() {
    return {
      visible: false,
    };
  },
  mixins: [constants],
  mounted() {},
  computed: {
    isMarketingView() {
      return this.$route.name === this.ROUTES.HOME.name;
    },
  },
};
</script>

<style lang="scss">
.menu-toggle {
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
}

.mobile-menu-container {
  max-width: 100%;
  margin: 0;
  top: 0;
  background: white;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
  position: fixed;
  z-index: 100000;

  ul {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 5rem;

    li {
      padding-bottom: rem(10px);
    }
  }
}
.close-container {
  position: relative;
  .close {
    position: absolute;
    right: 10px;
    top: 15px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    z-index: 9999999;
    cursor: pointer;

    &:hover {
      opacity: 1;
    }

    &:before,
    &:after {
      position: absolute;
      left: 15px;
      content: ' ';
      height: 33px;
      width: 2px;
      background-color: #333;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}

@media only screen and (min-width: 800px) {
  .menu-toggle {
    display: none;
  }
}
@media only screen and (max-width: 800px) {
  .desktop-menu-container {
    display: none;
  }
}
</style>
