var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isRenewable)?_c('form',{staticClass:"loan-application-form",attrs:{"autocomplete":"off"},on:{"submit":_vm.handleSubmit}},[_c('InputField',{attrs:{"name":"bot-field","label":"Don’t fill this out if you're human","autocomplete":"random","isHoneyPot":""},model:{value:(_vm.formData.honeypot),callback:function ($$v) {_vm.$set(_vm.formData, "honeypot", $$v)},expression:"formData.honeypot"}}),_c('Margins',[(_vm.FEATURE_LOGIN && !_vm.isPhysicalStore)?[_c('h2',[_vm._v(" "+_vm._s(_vm.$t('HELLO'))+", "+_vm._s(_vm.loginInfo.firstName)+" "+_vm._s(_vm.loginInfo.lastName)+"! ")])]:[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":((_vm.$t('FORM.FIRST_NAME')) + " *"),"name":"firstName","autocomplete":"first-name","error":_vm.errors.first('firstName'),"data-vv-as":_vm.$t('FORM.VALIDATOR.FIRST_NAME')},model:{value:(_vm.formData.firstName),callback:function ($$v) {_vm.$set(_vm.formData, "firstName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.firstName"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"margins__mobile-md-double",attrs:{"label":((_vm.$t('FORM.LAST_NAME')) + " *"),"name":"lastName","autocomplete":"family-name","error":_vm.errors.first('lastName'),"data-vv-as":_vm.$t('FORM.VALIDATOR.LAST_NAME')},model:{value:(_vm.formData.lastName),callback:function ($$v) {_vm.$set(_vm.formData, "lastName", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.lastName"}})],1)],1),_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({
          required: true,
          regex: _vm.REGEX.SE.identificationNumber,
        }),expression:"{\n          required: true,\n          regex: REGEX.SE.identificationNumber,\n        }"}],staticClass:"margins__double",attrs:{"label":((_vm.$t('FORM.ID_NUMBER')) + " *"),"name":"identificationNumber","placeholder":"00000000-0000","error":_vm.errors.first('identificationNumber'),"data-vv-as":_vm.$t('FORM.VALIDATOR.ID_NUMBER')},model:{value:(_vm.formData.identificationNumber),callback:function ($$v) {_vm.$set(_vm.formData, "identificationNumber", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.identificationNumber"}})],_c('b-row',{staticClass:"margins__double"},[_c('b-col',{attrs:{"md":"6"}},[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true, email: true }),expression:"{ required: true, email: true }"}],attrs:{"type":"email","label":((_vm.$t('FORM.EMAIL')) + " *"),"name":"email","autocomplete":"email","error":_vm.errors.first('email')},model:{value:(_vm.formData.email),callback:function ($$v) {_vm.$set(_vm.formData, "email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.email"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: _vm.REGEX.SE.mobile,
          }),expression:"{\n            required: true,\n            regex: REGEX.SE.mobile,\n          }"}],staticClass:"margins__mobile-md-double",attrs:{"label":((_vm.$t('FORM.MOBILE')) + " *"),"name":"mobile","placeholder":"+46-000000000","autocomplete":"tel","error":_vm.errors.first('mobile'),"prefix":"🇸🇪"},model:{value:(_vm.formData.mobile),callback:function ($$v) {_vm.$set(_vm.formData, "mobile", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.mobile"}})],1)],1),_c('b-row',{staticClass:"margins__double"},[_c('b-col',[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":((_vm.$t('FORM.ADDRESS')) + " *"),"name":"address","autocomplete":"street-address","error":_vm.errors.first('address'),"data-vv-as":_vm.$t('FORM.VALIDATOR.ADDRESS')},model:{value:(_vm.formData.address),callback:function ($$v) {_vm.$set(_vm.formData, "address", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.address"}})],1),_c('b-col',{attrs:{"md":"2"}},[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({
            required: true,
            regex: _vm.REGEX.SE.postalCode,
          }),expression:"{\n            required: true,\n            regex: REGEX.SE.postalCode,\n          }"}],staticClass:"margins__mobile-md-double",attrs:{"label":((_vm.$t('FORM.ZIP_CODE')) + " *"),"placeholder":"00000","name":"zipCode","autocomplete":"postal-code","error":_vm.errors.first('zipCode'),"data-vv-as":_vm.$t('FORM.VALIDATOR.ZIP_CODE')},model:{value:(_vm.formData.zipCode),callback:function ($$v) {_vm.$set(_vm.formData, "zipCode", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.zipCode"}})],1),_c('b-col',[_c('InputField',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],staticClass:"margins__mobile-md-double",attrs:{"label":((_vm.$t('FORM.CITY')) + " *"),"name":"city","autocomplete":"address-level2","error":_vm.errors.first('city'),"data-vv-as":_vm.$t('FORM.VALIDATOR.CITY')},model:{value:(_vm.formData.city),callback:function ($$v) {_vm.$set(_vm.formData, "city", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.city"}})],1)],1),_c('Separator',{attrs:{"hasLine":"","size":"large"}}),_c('Check',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.$t('FORM.PERSONAL_INFO_CONSENT'),"name":"personalInfoConsent"},model:{value:(_vm.formData.personalInfoConsent),callback:function ($$v) {_vm.$set(_vm.formData, "personalInfoConsent", $$v)},expression:"formData.personalInfoConsent"}}),_c('Check',{directives:[{name:"validate",rawName:"v-validate",value:({ required: true }),expression:"{ required: true }"}],attrs:{"label":_vm.privacyPolicyConsent,"name":"privacyConsent"},model:{value:(_vm.formData.privacyConsent),callback:function ($$v) {_vm.$set(_vm.formData, "privacyConsent", $$v)},expression:"formData.privacyConsent"}}),(
        _vm.errors.first('personalInfoConsent') || _vm.errors.first('privacyConsent')
      )?_c('div',{staticClass:"check__error"},[_vm._v(" "+_vm._s(_vm.$t('FORM.VALIDATOR.TERMS'))+" ")]):_vm._e(),_c('Separator',{attrs:{"size":"small"}}),_c('Radio',{attrs:{"id":"radio","label":_vm.renewableOrderText,"name":"renewableBundleOrder","checked":""}}),_c('div',{staticClass:"text-center margins__triple"},[_c('p',[_vm._v(_vm._s(_vm.$t('FORM.CREDIT_CHECK_INFO')))])]),_c('div',{staticClass:"text-center"},[_c('Button',{attrs:{"type":"submit"}},[_vm._v(_vm._s(_vm.$t('CONTINUE')))])],1)],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }