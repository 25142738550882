<template>
  <Container size="full" class="p-0">
    <b-row align-v="center" no-gutters>
      <b-col cols="auto" md="6">
        <figure>
          <img
            alt="Clocks for watches"
            loading="lazy"
            src="@/assets/images/clocks-for-watches.jpeg"
          />
        </figure>
        <Separator class="d-md-none" size="medium" />
      </b-col>
      <b-col class="text-center text-md-left" md="6" order="last">
        <Margins>
          <h2 class="mx-md-5">
            {{ $t('MARKETING.CLOCKS_FOR_WATCHES.TITLE') }}
          </h2>
          <p class="text-small mx-md-5">
            {{ $t('MARKETING.CLOCKS_FOR_WATCHES.TEXT') }}
          </p>
        </Margins>
      </b-col>
    </b-row>
  </Container>
</template>

<script>
import { Container, Separator, Margins } from '@/components';

export default {
  name: 'MarketingSectionThree',
  components: {
    Container,
    Separator,
    Margins,
  },
};
</script>
