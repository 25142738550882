import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

import {
  ASK_QUESTION,
  BACK_OFFICE_LOGIN,
  BACK_OFFICE_LOGOUT,
  CREATE_ACCOUNT,
  FINALIZE_ACCOUNT_REGISTRATION,
  GET_APPLICATION_STATUS,
  GET_BUNDLE_ORDERS,
  GET_BUNDLE_ORDER_DETAILS,
  GET_BUNDLES,
  GET_RENEWABLE_BUNDLE,
  GET_INDIVIDUALIZED_TERMS,
  GET_IS_PHYSICAL_STORE,
  GET_LOGIN_INFO,
  GET_STORES,
  HAS_ERROR,
  NOTIFICATION,
  HAS_LOAN_APPLICATION_ERROR,
  IS_BUNDLE_ORDER_COMPLETE,
  IS_LOADING,
  RESET_STORE,
  SAVE_BUNDLE_ORDER_DELIVERY_INFO,
  SET_APPLICATION_STATUS,
  SET_BACK_OFFICE_LOGGED_IN,
  SET_BACK_OFFICE_USER,
  SET_BUNDLE_ORDER_DELIVERY_INFO,
  SET_BUNDLE_ORDERS,
  SET_BUNDLES,
  SET_CURRENT_STEP,
  SET_INDIVIDUALIZED_TERMS,
  SET_IS_PHYSICAL_STORE,
  SET_LANGUAGE,
  SET_LOAN_APPLICATION_DATA,
  SET_LOAN_APPLICATION_DECISION,
  SET_LOGIN_INFO,
  SET_ORDER_HANDED_OVER,
  SET_SELECTED_BUNDLE,
  SET_SIGNATURE_STATUS,
  SET_SIGNING_DATA,
  SET_STORES,
  SIGN_WITH_BANK_ID,
  SUBMIT_LOAN_APPLICATION,
  SUBMIT_RENEWAL_APPLICATION,
  ACCEPT_INVITATION,
  BACK_OFFICE_PASSWORD_LOST,
  BACK_OFFICE_PASSWORD_RESET,
  GET_BUNDLE_ORDER_PRODUCTS,
  GET_RETURNED_ORDER_PRODUCTS,
  GET_RENEWABLE_ORDER,
  IS_RENEWABLE_ORDER,
  SET_SERVICES,
} from '@/types';
import ApiService from '@/api';
import {
  DECISION_TYPES,
  ORDER_STATUSES,
  PERSISTED_STORE_KEY,
  ROUTES,
  SIGNATURE_STATUSES,
  STEPS,
  USER_ROLE,
} from './constants';
import router from './router';
import i18n from './i18n';
import { changeProperty, getPersistedStore } from './utils';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: PERSISTED_STORE_KEY,
  storage: window.localStorage,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: (state) => ({
    language: state.language,
    currentStep: state.currentStep,
    selectedBundle: state.selectedBundle,
    loginInfo: state.loginInfo,
    loanApplicationData: state.loanApplicationData,
    loanApplicationDecision: state.loanApplicationDecision,
    individualizedTerms: state.individualizedTerms,
    signingData: state.signingData,
    bundleOrderDeliveryInfo: state.bundleOrderDeliveryInfo,
    backOfficeLoggedIn: !!state.backOfficeLoggedIn,
    backOfficeUser: state.backOfficeUser,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

const persistedStore = getPersistedStore();

export default new Vuex.Store({
  plugins: [vuexLocalStorage.plugin],
  state: {
    language: persistedStore.language || i18n.locale,
    currentStep: persistedStore.currentStep || STEPS.select,
    isPhysicalStore: null,
    stores: [],
    bundles: [],
    services: [],
    selectedBundle: persistedStore.selectedBundle || null,
    loginInfo: null,
    loanApplicationData: persistedStore.loanApplicationData || null,
    isLoading: false,
    hasError: false,
    notification: null,
    hasLoanApplicationError: false,
    applicationStatus: null,
    loanApplicationDecision: null,
    individualizedTerms: persistedStore.individualizedTerms || [],
    signingData: null,
    signatureStatus: null,
    bundleOrderDeliveryInfo: persistedStore.bundleOrderDeliveryInfo || null,
    bundleOrders: [],
    backOfficeLoggedIn: persistedStore.backOfficeLoggedIn || false,
    backOfficeUser: persistedStore.backOfficeUser || null,
  },
  getters: {
    isAdmin: (state) => {
      return state.backOfficeUser?.role === USER_ROLE.ADMIN;
    },
  },
  actions: {
    [SET_LANGUAGE]({ commit }, language) {
      commit(SET_LANGUAGE, language);
    },
    async [GET_IS_PHYSICAL_STORE]({ commit, state }) {
      if (state.isPhysicalStore !== null) {
        return state.isPhysicalStore;
      }

      try {
        const isStore = await ApiService.isStore();

        commit(SET_IS_PHYSICAL_STORE, isStore);

        return isStore; // this is needed for query that is made in router
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_STORES]({ commit }) {
      try {
        const stores = await ApiService.getStores();

        commit(SET_STORES, stores);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_BUNDLES]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const response = await ApiService.getBundles();

        commit(SET_BUNDLES, response.bundles);
        commit(SET_SERVICES, response.services);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_SELECTED_BUNDLE]({ commit }, bundle) {
      commit(SET_SELECTED_BUNDLE, bundle);
      commit(SET_CURRENT_STEP, bundle ? STEPS.apply : STEPS.select);
    },
    async [GET_RENEWABLE_BUNDLE]({ commit }, referenceNumber) {
      try {
        commit(IS_LOADING, true);

        const bundle = await ApiService.getRenewableBundle(referenceNumber);
        commit(SET_SELECTED_BUNDLE, bundle);

        const services = await ApiService.getServices();
        commit(SET_SERVICES, services);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [ASK_QUESTION]({ commit }, data) {
      try {
        commit(IS_LOADING, true);

        await ApiService.askQuestion(data);
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_LOGIN_INFO]({ commit }) {
      try {
        commit(IS_LOADING, true);

        const response = await ApiService.getLoginInfo();
        commit(SET_LOGIN_INFO, response);

        router.push({ name: ROUTES.LOAN_APPLICATION.name });
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    [SET_LOAN_APPLICATION_DATA]({ commit }, data) {
      commit(SET_LOAN_APPLICATION_DATA, data);
    },
    async [SUBMIT_LOAN_APPLICATION]({ commit, state, dispatch, getters }) {
      try {
        commit(IS_LOADING, true);

        const response = await ApiService.submitLoanApplication(
          state.loanApplicationData
        );

        commit(SET_LOAN_APPLICATION_DECISION, response);

        if (!state.isPhysicalStore || getters.isAdmin) {
          dispatch(SAVE_BUNDLE_ORDER_DELIVERY_INFO);
        }

        const {
          APPROVED,
          MANUAL_INSPECTION,
          SUPPLEMENTING_REQUIRED,
          NONE,
          DENIED,
        } = DECISION_TYPES;

        switch (response.decision) {
          case APPROVED:
            commit(SET_CURRENT_STEP, STEPS.sign);
            router.push({ name: ROUTES.DECISION_SIGN.name });
            break;
          case MANUAL_INSPECTION:
          case SUPPLEMENTING_REQUIRED:
          case NONE:
            commit(SET_LOAN_APPLICATION_DATA, null);
            commit(SET_CURRENT_STEP, STEPS.decision);
            router.push({ name: ROUTES.DECISION_MANUAL_INSPECTION.name });
            break;
          case DENIED:
            commit(SET_LOAN_APPLICATION_DATA, null);
            commit(SET_CURRENT_STEP, STEPS.decision);
            router.push({ name: ROUTES.DECISION_DENIED.name });
            break;
          default:
        }
      } catch (error) {
        commit(HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [SUBMIT_RENEWAL_APPLICATION]({ commit, state, dispatch, getters }) {
      try {
        commit(IS_LOADING, true);

        const response = await ApiService.submitRenewalApplication(
          state.loanApplicationData
        );

        commit(SET_LOAN_APPLICATION_DECISION, response);

        if (!state.isPhysicalStore || getters.isAdmin) {
          dispatch(SAVE_BUNDLE_ORDER_DELIVERY_INFO);
        }

        const {
          APPROVED,
          MANUAL_INSPECTION,
          SUPPLEMENTING_REQUIRED,
          NONE,
          DENIED,
        } = DECISION_TYPES;

        switch (response.decision) {
          case APPROVED:
            commit(SET_CURRENT_STEP, STEPS.sign);
            router.push({ name: ROUTES.DECISION_SIGN.name });
            break;
          case MANUAL_INSPECTION:
          case SUPPLEMENTING_REQUIRED:
          case NONE:
            commit(SET_LOAN_APPLICATION_DATA, null);
            commit(SET_CURRENT_STEP, STEPS.decision);
            router.push({ name: ROUTES.DECISION_MANUAL_INSPECTION.name });
            break;
          case DENIED:
            commit(SET_LOAN_APPLICATION_DATA, null);
            commit(SET_CURRENT_STEP, STEPS.decision);
            router.push({ name: ROUTES.DECISION_DENIED.name });
            break;
          default:
        }
      } catch (error) {
        commit(HAS_LOAN_APPLICATION_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_INDIVIDUALIZED_TERMS]({ commit, state }, type) {
      try {
        commit(IS_LOADING, true);

        const data = {
          accountNumber: state.loanApplicationDecision.accountNumber,
          amount: state.loanApplicationDecision.approvedAmount,
          bankProductType: state.selectedBundle.bankProductType,
          termsType: type,
        };
        const termsData = await ApiService.getIndividualizedTerms(data);

        commit(SET_INDIVIDUALIZED_TERMS, termsData);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [SIGN_WITH_BANK_ID]({ commit, state }) {
      try {
        commit(IS_LOADING, true);

        const data = {
          governmentId: state.loanApplicationData.identificationNumber,
          accountNumber: state.loanApplicationDecision.accountNumber,
          referenceNumber: state.loanApplicationDecision.referenceNumber,
          successUrl: `${window.location.origin}${ROUTES.BANK_ID_SUCCESS.path}`,
          failUrl: `${window.location.origin}${ROUTES.BANK_ID_FAIL.path}`,
        };
        const signingData = await ApiService.createSigningSession(data);

        commit(SET_SIGNING_DATA, signingData);

        window.location.href = signingData.signingUrl; // eslint-disable-line require-atomic-updates
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [FINALIZE_ACCOUNT_REGISTRATION]({ commit, state }) {
      try {
        const data = {
          accountNumber: state.loanApplicationDecision.accountNumber,
          signingReference: state.signingData.signingReference,
        };
        await ApiService.finalizeAccountRegistration(data);

        commit(RESET_STORE, true);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [SAVE_BUNDLE_ORDER_DELIVERY_INFO]({ commit, state }) {
      try {
        const data = {
          ...state.bundleOrderDeliveryInfo,
          referenceNumber: state.loanApplicationDecision.referenceNumber,
        };
        const response = await ApiService.saveBundleOrderDeliveryInfo(data);

        commit(SET_BUNDLE_ORDER_DELIVERY_INFO, response);
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [IS_BUNDLE_ORDER_COMPLETE]({ commit, dispatch, state }) {
      try {
        commit(IS_LOADING, true);

        const data = {
          signingReference: state.signingData.signingReference,
          referenceNumber: state.loanApplicationDecision.referenceNumber,
          identificationNumber: state.loanApplicationData.identificationNumber,
          bundleInfo: state.selectedBundle,
          language: state.language,
        };
        const signatureStatus = await ApiService.isBundleOrderComplete(data);

        commit(SET_SIGNATURE_STATUS, signatureStatus);

        if (signatureStatus === SIGNATURE_STATUSES.COMPLETE) {
          await dispatch(FINALIZE_ACCOUNT_REGISTRATION);
        } else {
          router.push({ name: ROUTES.BANK_ID_FAIL.name });
        }
      } catch (error) {
        router.push({ name: ROUTES.BANK_ID_FAIL.name });
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_APPLICATION_STATUS]({ commit, state }) {
      try {
        const referenceNumber = state.loanApplicationDecision.referenceNumber;
        const applicationStatus = await ApiService.getApplicationStatus(
          referenceNumber
        );

        commit(SET_APPLICATION_STATUS, applicationStatus);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [CREATE_ACCOUNT]({ commit, state }) {
      try {
        const referenceNumber = state.loanApplicationDecision.referenceNumber;
        const response = await ApiService.createAccount(referenceNumber);

        commit(SET_LOAN_APPLICATION_DECISION, {
          ...response,
          ...state.applicationStatus,
        });

        commit(SET_CURRENT_STEP, STEPS.sign);
        router.push({ name: ROUTES.DECISION_SIGN.name });
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    // Back office
    async [GET_BUNDLE_ORDERS]({ commit }, data) {
      try {
        commit(IS_LOADING, true);

        const orders = await ApiService.getBundleOrders(data);

        commit(SET_BUNDLE_ORDERS, orders);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [GET_BUNDLE_ORDER_DETAILS]({ commit }, referenceNumber) {
      try {
        return await ApiService.getBundleOrderDetails(referenceNumber);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_BUNDLE_ORDER_PRODUCTS]({ commit }, referenceNumber) {
      try {
        return await ApiService.getBundleOrderProducts(referenceNumber);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_RETURNED_ORDER_PRODUCTS]({ commit }, referenceNumber) {
      try {
        return await ApiService.getReturnedOrderProducts(referenceNumber);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [GET_RENEWABLE_ORDER]({ commit }, referenceNumber) {
      try {
        const order = await ApiService.getRenewableOrder(referenceNumber);
        if (Object.keys(order).length) {
          return order;
        } else {
          return {};
        }
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [IS_RENEWABLE_ORDER]({ commit }, referenceNumber) {
      try {
        return await ApiService.isRenewableOrder(referenceNumber);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [SET_ORDER_HANDED_OVER]({ commit }, data) {
      try {
        await ApiService.changeOrderStatusToHandedOver(data);
        commit(SET_ORDER_HANDED_OVER, data.referenceNumber);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [BACK_OFFICE_LOGIN]({ commit }, formData) {
      try {
        const user = await ApiService.login(formData);
        commit(SET_BACK_OFFICE_LOGGED_IN, true);
        commit(SET_BACK_OFFICE_USER, user);
      } catch (error) {
        commit(HAS_ERROR, error);
      }
    },
    async [BACK_OFFICE_LOGOUT]({ commit }) {
      try {
        await ApiService.logout();
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(SET_BACK_OFFICE_USER, null);
        commit(SET_BACK_OFFICE_LOGGED_IN, false);
        router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name });
      }
    },
    async [ACCEPT_INVITATION]({ commit }, data) {
      try {
        await ApiService.acceptInvitation(data.invitationId, data.formData);
        commit(NOTIFICATION, data.notification);

        setTimeout(
          () => router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name }),
          5000
        );
      } catch (error) {
        commit(HAS_ERROR, error);
        throw new Error(error);
      }
    },
    async [BACK_OFFICE_PASSWORD_LOST]({ commit }, data) {
      try {
        commit(IS_LOADING, true);
        await ApiService.requestPasswordReset(data.formData);
        commit(NOTIFICATION, data.notification);
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
    async [BACK_OFFICE_PASSWORD_RESET]({ commit }, data) {
      try {
        commit(IS_LOADING, true);
        await ApiService.resetPassword(data.tokenId, data.formData);
        commit(NOTIFICATION, data.notification);
        setTimeout(
          () => router.push({ name: ROUTES.BACK_OFFICE_LOGIN.name }),
          5000
        );
      } catch (error) {
        commit(HAS_ERROR, error);
      } finally {
        commit(IS_LOADING, false);
      }
    },
  },
  mutations: {
    [RESET_STORE](state, isHalfSweep) {
      state.currentStep = STEPS.select;
      state.selectedBundle = null;
      state.loginInfo = null;
      state.loanApplicationData = null;
      state.applicationStatus = null;
      state.loanApplicationDecision = isHalfSweep
        ? {
            referenceNumber: state.loanApplicationDecision.referenceNumber,
            accountNumber: state.loanApplicationDecision.accountNumber,
          }
        : null;
      state.individualizedTerms = [];
      state.signingData = null;
      state.bundleOrderDeliveryInfo = null;
    },
    [SET_LANGUAGE](state, language) {
      state.language = language;
    },
    [IS_LOADING](state, loading) {
      state.isLoading = loading;
    },
    [HAS_ERROR](state, error) {
      state.hasError = error;
    },
    [NOTIFICATION](state, notification) {
      state.notification = notification;
    },
    [SET_CURRENT_STEP](state, step) {
      state.currentStep = step;
    },
    [SET_IS_PHYSICAL_STORE](state, isStore) {
      state.isPhysicalStore = isStore;
    },
    [SET_STORES](state, stores) {
      state.stores = stores;
    },
    [SET_BUNDLES](state, bundles) {
      state.bundles = bundles;
    },
    [SET_SERVICES](state, services) {
      state.services = services;
    },
    [SET_SELECTED_BUNDLE](state, bundle) {
      state.selectedBundle = bundle;
    },
    [SET_LOGIN_INFO](state, data) {
      state.loginInfo = data;
    },
    [SET_LOAN_APPLICATION_DATA](state, data) {
      state.loanApplicationData = data;
    },
    [HAS_LOAN_APPLICATION_ERROR](state, error) {
      state.hasLoanApplicationError = error;
    },
    [SET_APPLICATION_STATUS](state, status) {
      state.applicationStatus = status;
    },
    [SET_LOAN_APPLICATION_DECISION](state, decision) {
      state.loanApplicationDecision = decision;
    },
    [SET_INDIVIDUALIZED_TERMS](state, termsData) {
      state.individualizedTerms.push(termsData);
    },
    [SET_BUNDLE_ORDER_DELIVERY_INFO](state, deliveryData) {
      state.bundleOrderDeliveryInfo = deliveryData;
    },
    [SET_SIGNING_DATA](state, data) {
      state.signingData = data;
    },
    [SET_SIGNATURE_STATUS](state, status) {
      state.signatureStatus = status;
    },
    // Back office
    [SET_BUNDLE_ORDERS](state, orders) {
      state.bundleOrders = orders;
    },
    [SET_ORDER_HANDED_OVER](state, referenceNumber) {
      state.bundleOrders = changeProperty(
        state.bundleOrders,
        'referenceNumber',
        referenceNumber,
        'status',
        ORDER_STATUSES.HANDED_OVER
      );
    },
    [SET_BACK_OFFICE_LOGGED_IN](state, backOfficeLoggedIn) {
      state.backOfficeLoggedIn = backOfficeLoggedIn;
    },
    [SET_BACK_OFFICE_USER](state, user) {
      state.backOfficeUser = user;
    },
  },
});
