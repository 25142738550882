<template>
  <div class="table-responsive">
    <ApplicationHandOverModal ref="hand-over-modal" />

    <table class="table">
      <thead>
        <tr>
          <th></th>
          <th class="text-left">
            {{ $t('BACK_OFFICE_ORDERS.REFERENCE_NUMBER') }}
          </th>
          <th class="text-left px-3">
            {{ $t('BACK_OFFICE_ORDERS.TOTAL_AMOUNT') }} ({{ $t('CURRENCY') }})
          </th>
          <th class="text-left px-3">
            {{ $t('BACK_OFFICE_ORDERS.PAYMENT_AMOUNT') }}
          </th>
          <th class="text-left px-3">
            {{ $t('BACK_OFFICE_ORDERS.BUNDLE_PRODUCT_CODE') }}
          </th>
          <th class="text-left px-3">{{ $t('BACK_OFFICE_ORDERS.CREATED') }}</th>
          <th class="text-left px-3">
            {{ $t('BACK_OFFICE_ORDERS.DELIVERY_STORE') }}
          </th>
          <th>{{ $t('BACK_OFFICE_ORDERS.STATUS') }}</th>
          <th class="text-center small px-3">
            {{ $t('BACK_OFFICE_ORDERS.ACTIONS') }}
          </th>
        </tr>
      </thead>
      <tbody v-if="filteredOrders.length === 0">
        <tr>
          <td colspan="8">{{ $t('ORDERS_NO_RESULTS') }}</td>
        </tr>
      </tbody>
      <tbody v-for="order in filteredOrders" v-else :key="order.createdDtime">
        <tr
          :class="[
            {
              'has-highlight': isHighlighted(order),
              'has-background': isActive(order),
            },
          ]"
        >
          <td>
            <Button
              :title="$t('CLOSE_DETAILS')"
              variant="ghost"
              @click="showCustomerDetails(order.referenceNumber)"
            >
              <template v-if="displayOrderDetails[order.referenceNumber]">
                <div>
                  <CloseDetailsSVG class="icon" />
                </div>
              </template>
              <template v-else>
                <div>
                  <ViewDetailsSVG class="icon" />
                </div>
              </template>
            </Button>
            <Button
              :title="$t('CLOSE_DETAILS')"
              variant="ghost"
              @click="showProductDetails(order.referenceNumber)"
            >
              <template v-if="displayOrderProducts[order.referenceNumber]">
                <div>
                  <ChevronUp class="icon" />
                </div>
              </template>
              <template v-else>
                <div>
                  <ChevronDown class="icon" />
                </div>
              </template>
            </Button>
          </td>
          <td>
            <strong>{{ order.referenceNumber }}</strong>
          </td>
          <td>
            <strong>{{ order.totalAmount }}</strong>
          </td>
          <td class="text-left px-3">
            {{ paymentAmount(order.totalAmount, order.financingCost) }}
          </td>
          <td>
            <strong>{{ order.bundleProductCode }}</strong>
          </td>
          <td class="px-3">
            {{
              new Intl.DateTimeFormat($i18n.locale, {
                dateStyle: 'short',
                timeStyle: 'short',
              }).format(new Date(order.createdDtime))
            }}
          </td>
          <td>
            {{ order.deliveryStore }}
          </td>
          <td class="text-center px-3">
            <Badge :variant="orderStatusVariant(order.status, order.decision)">
              {{ orderTranslation(order.status, order.decision) }}
            </Badge>
          </td>
          <td
            :class="[
              'text-center px-3',
              { ['no-padding']: hasNoPadding(order) },
            ]"
          >
            <Button
              v-if="order.status === ORDER_STATUSES.COMPLETED"
              :title="$t('HAND_OVER')"
              variant="ghost"
              @click="showHandOverModal(order)"
            >
              <CheckSVG class="icon" />
            </Button>
          </td>
        </tr>
        <tr
          v-if="displayOrderDetails[order.referenceNumber]"
          :class="[
            {
              'has-background': isHighlighted(order) || isActive(order),
            },
          ]"
        >
          <td colspan="9">
            <BackOfficeBundleOrderDetails
              :orderDetails="bundleOrderDetails[order.referenceNumber]"
            />
          </td>
        </tr>
        <tr
          v-if="displayOrderProducts[order.referenceNumber]"
          class="without-border"
          :class="[
            {
              'has-background': isHighlighted(order) || isActive(order),
            },
          ]"
        >
          <td colspan="9">
            <BackOfficeBundleOrderProducts
              :is-renewal="isRenewal(order)"
              :products="bundleOrderProducts[order.referenceNumber]"
              :renewed-bundle="renewedBundle[order.referenceNumber]"
              :returned-products="returnedProducts[order.referenceNumber]"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  GET_BUNDLE_ORDER_DETAILS,
  GET_BUNDLE_ORDER_PRODUCTS,
  GET_RENEWABLE_ORDER,
  GET_RETURNED_ORDER_PRODUCTS,
} from '@/types';
import { Badge, Button } from '@/components';
import { constants } from '@/mixins';
import BackOfficeBundleOrderDetails from './BackOfficeBundleOrderDetails.vue';
import BackOfficeBundleOrderProducts from './BackOfficeBundleOrderProducts.vue';
import CheckSVG from '@/assets/images/check-solid.svg';
import ViewDetailsSVG from '@/assets/images/eye-solid.svg';
import CloseDetailsSVG from '@/assets/images/eye-slash-solid.svg';
import ChevronDown from '@/assets/images/chevron-down.svg';
import ChevronUp from '@/assets/images/chevron-up.svg';
import ApplicationHandOverModal from './ApplicationHandOverModal.vue';

export default {
  name: 'BackOfficeBundleOrdersTable',
  mixins: [constants],
  components: {
    Badge,
    Button,
    BackOfficeBundleOrderDetails,
    BackOfficeBundleOrderProducts,
    CheckSVG,
    ApplicationHandOverModal,
    ViewDetailsSVG,
    CloseDetailsSVG,
    ChevronDown,
    ChevronUp,
  },
  data() {
    return {
      displayOrderDetails: {},
      bundleOrderDetails: {},
      displayOrderProducts: {},
      bundleOrderProducts: {},
      renewedBundle: {},
      returnedProducts: {},
    };
  },
  props: {
    filteredOrders: Array,
  },
  methods: {
    ...mapActions([
      GET_BUNDLE_ORDER_DETAILS,
      GET_BUNDLE_ORDER_PRODUCTS,
      GET_RENEWABLE_ORDER,
      GET_RETURNED_ORDER_PRODUCTS,
    ]),
    orderStatusVariant(status, decision) {
      if (decision === this.DECISION_TYPES.DENIED) {
        return 'error';
      }

      switch (status) {
        case this.ORDER_STATUSES.CANCELLED:
        case this.ORDER_STATUSES.DENIED:
          return 'error';
        case this.ORDER_STATUSES.PENDING:
        case this.ORDER_STATUSES.SUBMITTED:
        case this.ORDER_STATUSES.AWAITING_RENEWAL:
          return 'info';
        case this.ORDER_STATUSES.COMPLETED:
        case this.ORDER_STATUSES.READY_TO_RENEW:
          return 'success';
        case this.ORDER_STATUSES.HANDED_OVER:
        case this.ORDER_STATUSES.RENEWED:
          return 'done';
        default:
      }
    },
    orderTranslation(status, decision) {
      if (
        decision === this.DECISION_TYPES.DENIED ||
        status === this.DECISION_TYPES.DENIED
      ) {
        return this.$t(`DECISION_TYPES.${this.DECISION_TYPES.DENIED}`);
      }

      return this.$t(`ORDER_STATUSES.${status}`);
    },
    paymentAmount(totalAmount, financingCost) {
      return totalAmount - financingCost;
    },
    async showCustomerDetails(referenceNumber) {
      if (this.displayOrderDetails[referenceNumber]) {
        this.closeOrderDetails(referenceNumber);
      } else {
        this.getOrderDetails(referenceNumber);
      }
    },
    async showProductDetails(referenceNumber) {
      if (this.displayOrderProducts[referenceNumber]) {
        this.closeOrderProducts(referenceNumber);
      } else {
        this.getOrderProducts(referenceNumber);
        this.getRenewedBundle(referenceNumber);
        this.getReturnedProducts(referenceNumber);
      }
    },
    async getBundleOrdersWithDelivered(referenceNumber) {
      const orders = await this.GET_BUNDLE_ORDER_PRODUCTS(referenceNumber);
      return orders.map((order) => {
        return { ...order, delivered: this.isDelivered(referenceNumber) };
      });
    },
    async getOrderProducts(referenceNumber) {
      if (!this.bundleOrderProducts[referenceNumber]) {
        this.$set(
          this.bundleOrderProducts,
          referenceNumber,
          await this.getBundleOrdersWithDelivered(referenceNumber)
        );
      }
      this.$set(this.displayOrderProducts, referenceNumber, true);
    },
    closeOrderProducts(referenceNumber) {
      this.$set(this.displayOrderProducts, referenceNumber, false);
    },
    closeOrderDetails(referenceNumber) {
      this.$set(this.displayOrderDetails, referenceNumber, false);
    },
    async getOrderDetails(referenceNumber) {
      if (!this.bundleOrderDetails[referenceNumber]) {
        this.$set(
          this.bundleOrderDetails,
          referenceNumber,
          await this.GET_BUNDLE_ORDER_DETAILS(referenceNumber)
        );
      }
      this.$set(this.displayOrderDetails, referenceNumber, true);
    },
    async getRenewedBundle(referenceNumber) {
      if (!this.renewedBundle[referenceNumber]) {
        this.$set(
          this.renewedBundle,
          referenceNumber,
          await this.GET_RENEWABLE_ORDER(referenceNumber)
        );
      }
      this.$set(this.displayOrderProducts, referenceNumber, true);
    },
    async getReturnedProducts(referenceNumber) {
      if (!this.returnedProducts[referenceNumber]) {
        this.$set(
          this.returnedProducts,
          referenceNumber,
          await this.GET_RETURNED_ORDER_PRODUCTS(referenceNumber)
        );
      }
      this.$set(this.displayOrderProducts, referenceNumber, true);
    },
    showHandOverModal(order) {
      this.$refs['hand-over-modal'].showModal(order);
    },
    hasNoPadding(order) {
      return order.status === this.ORDER_STATUSES.COMPLETED;
    },
    isHighlighted(order) {
      return order.referenceNumber === this.$route.query.referenceNumber;
    },
    isActive(order) {
      return (
        this.displayOrderDetails[order.referenceNumber] ||
        this.displayOrderProducts[order.referenceNumber]
      );
    },
    isRenewal(order) {
      return order.renewedFrom && order.renewedFrom !== '';
    },
    isDelivered(referenceNumber) {
      return [
        this.ORDER_STATUSES.COMPLETED,
        this.ORDER_STATUSES.SUBMITTED,
      ].includes(
        this.filteredOrders.find(
          (order) => order.referenceNumber === referenceNumber
        ).status
      );
    },
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
};
</script>
