<template>
  <div id="app" class="app">
    <Card v-if="isMarketingView" color="black" text="white">
      <p class="text-center text-uppercase my-2 px-3">
        {{ $t('MARKETING.DISCLAIMER') }}
      </p>
    </Card>
    <Header
      :showMarketingItems="showMarketingItems"
      :isMarketingView="isMarketingView"
    />
    <main :class="'route-' + $route.name">
      <transition name="fade-router" mode="out-in">
        <router-view />
      </transition>

      <Alert v-if="hasError" type="error" position="fixed" isDismissable>
        <Margins>
          <p v-html="errorMessage" />
          <p class="text-extra-small" v-html="errorCode"></p>
        </Margins>
      </Alert>

      <Alert v-if="notification" type="success" position="fixed" isDismissable>
        <Margins>
          <p v-html="notification" />
        </Margins>
      </Alert>
    </main>

    <Footer />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { GET_IS_PHYSICAL_STORE } from '@/types';
import { Header, Footer } from '@/containers';
import { Alert, Margins, Card } from '@/components';
import heartbeat from '@/heartbeat';
import { constants } from '@/mixins';
import { SUPPORT_EMAIL } from '@/constants';

export default {
  name: 'App',
  mixins: [constants],
  components: {
    Header,
    Alert,
    Margins,
    Card,
    Footer,
  },
  async mounted() {
    await this.GET_IS_PHYSICAL_STORE();
    this.handleAliveHeartbeat(this.backOfficeLoggedIn);
  },
  methods: {
    ...mapActions([GET_IS_PHYSICAL_STORE]),
    handleAliveHeartbeat(isLoggedIn) {
      heartbeat.handleAliveHeartbeat(isLoggedIn && this.isPhysicalStore);
    },
  },
  computed: {
    ...mapState([
      'hasError',
      'notification',
      'backOfficeLoggedIn',
      'isPhysicalStore',
    ]),
    isMarketingView() {
      return this.$route.name === this.ROUTES.HOME.name;
    },
    showMarketingItems() {
      return this.$route.name === this.ROUTES.FAQ.name || this.isMarketingView;
    },
    errorMessage() {
      return this.$t('DEFAULT_ERROR_MESSAGE', [
        '<br />',
        `<a href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>`,
      ]);
    },
    errorCode() {
      return this.$t('DEFAULT_ERROR_CODE', [
        this.hasError.message || this.hasError.status,
      ]);
    },
  },
  watch: {
    backOfficeLoggedIn(isLoggedIn) {
      this.handleAliveHeartbeat(isLoggedIn);
    },
  },
};
</script>

<style lang="scss">
main {
  background-color: $color-grey-disabled;
}
.app {
  display: grid;
  grid-template-rows: auto 1fr auto;
  min-height: 100vh;
}

.fade-router-enter-active,
.fade-router-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-router-enter,
.fade-router-leave-active {
  opacity: 0;
}
</style>
